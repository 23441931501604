@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__faq {
	@apply pb-10;

	.asteria-page__wrapper {
		@apply pb-10;
	}

	> .asteria-component__actions {
		@apply mb-4;
	}
}
