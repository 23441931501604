@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__print-details {
}

.asteria-component__print-details-content {
	.asteria-component__print-error-icon {
		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-error-normal-icon;
			}
		}
	}
}

.asteria-component__print-details-alert {
	.asteria-component__button {
		&.asteria-component__button--variant-link {
			@apply m-0 h-auto w-auto gap-2 p-0;
		}
	}

	&.asteria--state-error {
		@apply bg-alert-error-normal-background;

		> .asteria-component__title,
		> .asteria-component__typography-group > .asteria-component__title {
			@apply text-alert-error-normal-title;
		}

		> .asteria-component__text,
		> .asteria-component__typography-group > .asteria-component__text {
			@apply text-alert-error-normal-text;
		}
	}

	&.asteria--state-warning {
		@apply bg-alert-warning-normal-background;

		> .asteria-component__title,
		> .asteria-component__typography-group > .asteria-component__title {
			@apply text-alert-warning-normal-title;
		}

		> .asteria-component__text,
		> .asteria-component__typography-group > .asteria-component__text {
			@apply text-alert-warning-normal-text;
		}
	}
}

.asteria-component__print-details-table {
	@apply grid;

	grid-template-columns: auto auto /* auto */ auto minmax(0, 1fr) auto;

	> .asteria-component__table-row {
		@apply contents;

		> .asteria-component__table-cell {
			@apply px-2;
		}

		&:not(:last-child) {
			> .asteria-component__table-cell {
				@apply border-0 border-b border-solid border-border-normal;
			}
		}

		&.asteria--variant-error {
			> .asteria-component__table-cell {
				--color-icon-normal: theme('colors.alert.error.normal.text');

				@apply bg-alert-error-normal-background;

				.asteria-component__typography {
					@apply text-alert-error-normal-text;
				}
			}
		}
	}

	.asteria-component__table-header {
		> .asteria-component__table-cell {
			.asteria-component__button {
				.asteria-component__button {
					@apply opacity-0;
				}

				&:hover,
				&.asteria--state-active {
					.asteria-component__button {
						@apply opacity-100;
					}
				}
			}
		}
	}

	.asteria-component__table-cell {
		.asteria-component__button {
			@apply m-0 h-auto w-auto gap-2 p-0;
		}

		.asteria-component__typography {
			@apply truncate;
		}
	}
}
