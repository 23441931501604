@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__settings {
	@apply grid h-full gap-4;

	grid-template-rows: auto 1fr;

	> .asteria-component__form {
		> .asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				@apply px-0;
			}

			> .asteria-component__wrapper-footer {
				@apply px-8;
			}
		}
	}
}
