@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-view__auth {
	@apply relative grid h-full w-full flex-1 place-content-stretch items-center px-8 py-8 overflow-y-auto;
  @media (min-width: 768px) {
    @apply place-content-center overflow-y-hidden;
  }
}

.asteria-view__auth-content {
	> .asteria-component__form {

		@apply w-full;

    @media (min-width: 768px) {
      min-width: 500px;
    }
	}

	> .asteria-component__wrapper,
	> .asteria-component__form > .asteria-component__wrapper {
		> .asteria-component__wrapper-header {
			@apply pb-0;
		}

		> .asteria-component__wrapper-content {
			> .asteria-component__alert {
				@apply mb-2;
			}
		}

    > .asteria-component__wrapper-footer {

      @apply items-stretch flex-col;

      @media (min-width: 768px) {
        @apply flex-row;
      }

      > .asteria-component__wrapper-footer__section {
        > .asteria-component__button {

          @apply w-full justify-center;
      
          @media (min-width: 768px) {
            @apply w-auto;
          }
        }
      }
		}
	}

	&.asteria--variant-login {
		.asteria-view__auth-content-section {
			&.asteria--variant-password {
				> .asteria-component__button {
					@apply h-auto p-0;
				}
			}
		}

		&.asteria--state-step-COMPANY {
			.asteria-component__list {
				.asteria-component__list__item {
					@apply flex cursor-pointer justify-between border-0 border-b border-solid border-border-normal;
				}
			}
		}
	}

	&.asteria--variant-signup {
		> .asteria-component__wrapper,
		> .asteria-component__form > .asteria-component__wrapper {
			max-height: 70vh;
		}
	}

	&.asteria--variant-reset {
	}

	&.asteria--variant-recover {
	}

	&.asteria--variant-welcome {
	}

	&.asteria--variant-signup:not([class*='asteria--state-step']),
	&.asteria--variant-reset,
	&.asteria--variant-recover,
	&.asteria--variant-welcome {
		> .asteria-component__wrapper,
		> .asteria-component__form > .asteria-component__wrapper {
			> .asteria-component__wrapper-footer {
				.asteria-component__wrapper-footer__section {
					&.asteria-component__wrapper-footer__section--position-first {
						@apply hidden;
					}
				}
			}
		}
	}
}

.asteria-view__auth-switcher {
  @media (max-width: 767px) {
    @apply flex flex-col justify-center items-center gap-4;

    .asteria-component__button:not(.asteria-component__button--variant-select).asteria--size-sm {
      @apply
      bg-button-secondary-normal-background
      justify-center
      inline-flex
      h-10
      items-center
      whitespace-nowrap
      rounded-lg
      border-0
      px-4
      py-2
      focus:outline-none
      w-full
      rounded-2xl;
    
      .asteria-component__text {
        @apply text-button-secondary-normal-text no-underline text-lg;
      }
  
      &:hover {
        @apply bg-button-secondary-hover-background;
  
        .asteria-component__text {
          @apply text-button-secondary-hover-text;
        }
  
      }
  
      &.asteria--state-active {
        @apply bg-button-secondary-focus-background;
  
        .asteria-component__text {
          @apply text-button-secondary-focus-text;
        }
      }
    }
  }

  @media (min-width: 768px) {
    @apply flex-row;
  }

}

.asteria-view__auth-languages {

  @apply flex flex-col;
      
  @media (min-width: 768px) {
    @apply flex-row;
  }

	.asteria-component__button {
		&:not(.asteria--state-active) {
			@apply opacity-50;
		}
	}
}

.asteria-view__auth-homepage {
	@apply fixed bottom-0 left-1/2 -translate-x-1/2;
}
