@font-face {
	font-family: 'Intro Cond';
	font-weight: 100 400;
	src: url('intro-cond-regular.woff2');
}

@font-face {
	font-family: 'Intro Cond';
	font-weight: 500 900;
	src: url('intro-cond-bold.woff2');
}
