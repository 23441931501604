.asteria-component__credits__wrapper {
	text-align: center;
}
.asteria-component__wrapper__circle-content {
	position: relative;
	width: 200px;
	height: 200px;
	overflow: hidden;
	border-radius: 50%;
	display: flex;
	background: var(--wrapper__header__color);
	justify-content: center;
	align-items: center;
	&.asteria-component__wrapper__circle-content h2 {
		color: white;
		text-align: center;
		font-size: xx-large;
	}
}
.asteria-component__wrapper__square-content {
	position: relative;
	height: 152px;
	width: 176px;
	overflow: hidden;
	display: flex;
	--cross-grey: #c4c4c4;
	background: var(--cross-grey);
	justify-content: center;
	align-items: center;
	&.asteria-component__wrapper__circle-content h2 {
		color: white;
		text-align: center;
		font-size: xx-large;
	}
}
.asteria-component__wrapper__content__background__center {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	background-color: var(--splash__wrapper__background-color);
	box-shadow: 0px 2px 0px 0px #dadada inset;
	.asteria-component__wrapper__content__text__w-80 {
		width: 80%;
		line-height: 35px;
	}
}
.asteria-component__content__flex-center {
	display: flex;
	justify-content: center;
	.close {
		// position: absolute;
		// right: 32px;
		// top: 32px;
		width: 176px;
		height: 152px;
		// opacity: 0.3;
	}
	.close:before,
	.close:after {
		// position: absolute;
		// left: 15px;
		content: ' ';
		height: 100%;
		width: 2px;
		background-color: #727272;
	}
	.close:before {
		transform: rotate(45deg);
	}
	.close:after {
		transform: rotate(-45deg);
	}
}

// .asteria-component__credits-footer .asteria-component__button:first-of-type {
//     margin-left: auto;
// }

// Modal
.asteria-component__backdrop--variant-modal.asteria--size-small
	.asteria-component__modal {
	// &.asteria-component__sign_agreement__modal.asteria-component__modal {
	// width: 350px;
	// }
}
.asteria-component__wrapper .asteria-component__wrapper-content {
	&.asteria-component__credits__bookkeeping {
		--background-color-credits-bookkeeping: #fbf2ea;
		background-color: var(--background-color-credits-bookkeeping);
		margin: 5%;
		// padding: 25% 10% 30% 10%;
		padding: 50px 20px 55px 20px;
	}
	&.asteria-component__credits__bookkeeping .asteria-component__progress {
		width: 100%;
		margin-top: 8%;
	}
}

.asteria-component__icon-content-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	// padding: 20% 20% 20% 15%;
	padding: 50px 20px 55px 20px;
}

.asteria-component__shared_data_login {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 2px 0px 0px #dadada inset;
	--login-color: #31a3ae;
	.asteria-component__typography {
		text-align: left;
		border-left: 5px solid var(--login-color);
		padding: 16px;
		color: var(--login-color);
	}
	.asteria-component__icon {
		width: 10%;
		fill: var(--login-color);
	}
}

.asteria-component__customer__path-sign-agreement {
	// background:#FBF2EA;
	width: 544px;
	margin: 3%;
	// padding: 2%;
}

// .asteria-component__customer__path-signagreement .asteria-component__wrapper {
//     background:#FBF2EA;
// }
// .asteria-component__customer__path-signagreement .asteria-component__wrapper .asteria-component__wrapper-header {
//     border-bottom: 1px solid #d4c4bc;
// }

// .asteria-component__customer__path__content__title {
//     color: black;
// }
.asteria-component__alert--level-success
	.asteria-component__alert__content
	.asteria-component__text {
	&.asteria-component__main-text-bookkeeping {
		margin-top: 14%;
		font-size: 16px;
		text-align: center;
	}
}

.asteria-component__alert.asteria-component__alert--level-success
	.asteria-component__alert__content
	.asteria-component__text:first-of-type.asteria-component__sub-text-bookkeeping {
	margin: 4% 0% 15% 0%;
	font-size: larger;
	text-align: center;
}

.asteria-component__backdrop--variant-modal
	.asteria-component__modal
	.asteria-component__wrapper
	.asteria-component__credits__bookkeeping {
	--background-color-credits-bookkeeping: #fbf2ea;
	background-color: var(--background-color-credits-bookkeeping);
	margin: 0px;
	// padding: 25% 10% 30% 10%;
	padding: 50px 20px 55px 20px;
}

.asteria-component__wrapper__confirm_delete {
	height: 50%;
}
.asteria-component__content-border {
	// border-bottom: 1px solid #FDF2E9;
	border-top: 1px solid #fdf2e9;
}

.asteria-component__alert_image {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5rem;
}
