@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__faq-section {
	@apply pb-10;

	.asteria-page__wrapper {
		@apply pb-10;
	}

	> .asteria-component__actions {
		@apply mb-4;
	}

	.asteria-component__faq-section {
		@apply gap-8;

		grid-template-columns: 1fr;
	}
}
