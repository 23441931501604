@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-widget__layout {
	.asteria-component__breadcrumbs {
		@apply mb-4;

		.asteria-component__button {
			&.asteria-component__button--variant-link {
				@apply h-auto w-auto p-0;

				> .asteria-component__typography {
					@apply text-button-link-normal-text;
				}

				&:hover {
					> .asteria-component__typography {
						@apply text-button-link-hover-text;
					}
				}
			}
		}

		.asteria-component__title {
			@apply overflow-hidden text-ellipsis whitespace-nowrap;

			max-width: 250px;
		}
	}
}
