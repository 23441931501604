.asteria-component__step {
  position: relative;
  min-height: 1em;
  --color-grey: #d9d9d9;
  --color-dark-grey: grey;
}
.asteria-component__step + .asteria-component__step {
  margin-top: 2.3em;
}
.asteria-component__step > div:first-child {
  position: static;
  height: 0;
}
.asteria-component__step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}
.asteria-component__step.step-active {
  --color-step-active: #ffbe06;
  color: var(--color-step-active);
}
.asteria-component__step.step-active .asteria-component__step__circle {
  background-color: var(--color-step-active);
}
.asteria-component__step.step-active .asteria-component__step__title {
  --color-title-active: #512B2B;
  color: var(--color-title-active);
}
/* Circle */
.asteria-component__step__circle {
  background: var(--color-grey);
  position: relative;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 100%;
  color: var(--color-dark-grey);
  text-align: center;
}

/* Vertical Line */
.asteria-component__step__circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(0, 0, 0, 0.25);
}
.asteria-component__step:last-child .asteria-component__step__circle:after {
  display: none;
}

.asteria-component__step__title {
  line-height: 2.3em;
  color: grey;
}