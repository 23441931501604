@font-face {
	font-family: 'Futura PT';
	font-weight: 100 300;
	src: url('futura-light.woff2');
}
@font-face {
	font-family: 'Futura PT';
	font-weight: 400 600;
	src: url('futura-regular.woff2');
}

@font-face {
	font-family: 'Futura PT';
	font-weight: 700 900;
	src: url('futura-bold.woff2');
}
