@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__guides {
	@apply grid;

	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

	.asteria-component__guides-guide {
		&:first-child {
			@apply border-l-0;
		}
	}
}

.asteria-component__guides-guide {
	@apply box-border flex flex-col items-center justify-center gap-8 border-l border-solid border-border-normal px-8 py-14 text-center;

	@media (max-width: theme('screens.tablet')) {
		@apply border-0 p-4;
	}

	.asteria-component__icon {
		&:not(.asteria-component__button .asteria-component__icon) {
			&,
			.asteria-component__icon-content {
				@apply h-10 w-10;
			}
		}
	}

	> .asteria-component__typography-group {
		@apply flex flex-1 flex-col gap-4;
	}
}
