@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__integration-loading-dialog {
	.asteria-component__wrapper {
		.asteria-component__wrapper-content {
			@apply gap-2;

			.asteria-component__progress {
				@apply rounded-full;
			}
		}
	}
}
