.asteria-component__accordion_width-60 {
	width: 60%;
}
.asteria-component__aside_width-35 {
	width: 35%;
}
.asteria-component__feedback__prompt__width-100 {
	width: 100%;
}
.asteria-component__application__container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.asteria-component__text__reject {
	font-weight: 100;
	font-size: inherit;
	margin: 3px 0px;
}
.asteria-component__wrapper-content__no-padding {
	padding: 0px;
}
@media screen and (max-width: 700px) {
	.asteria-component__accordion_width-60 {
		width: 100%;
	}
	.asteria-component__aside_width-35 {
		width: 100%;
	}
	.asteria-component__application__container {
		flex-direction: column;
	}
}
