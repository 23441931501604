@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__layout__header {
	&.swedbank-header {
		@apply pl-4 pr-8;

		z-index: 5;
		height: 72px;
		background-color: rgb(251, 242, 234);
		box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px;

		.wings-header__logo {
			background-image: url('./images/logo.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			width: 150px;
			height: 32px;
		}

		> .asteria-component__group {
			@apply h-full;
		}

		.wings-header__button {
			@apply flex h-10 cursor-pointer items-center gap-2 px-4;

			&:hover {
				background-color: rgb(235, 235, 235);

				.asteria-component__icon {
					.asteria-component__icon-content {
						background-color: rgb(238, 112, 35);
					}
				}
			}
		}
	}
}

.asteria-component__layout__sidebar {
	&.swedbank-sidebar {
		@apply my-2 border-0 border-r border-solid border-border-normal;

		width: 236px;

		@include scroll();

		.wings-sidebar__item {
			@apply box-border flex h-10 cursor-pointer flex-row items-center gap-3 px-4;

			&:hover {
				background-color: rgba(212, 196, 188, 0.5);
			}

			.wings-sidebar__item__label {
				@apply flex-1;
				padding-left: 2px;

				.wings-sidebar__placeholder {
					@apply w-full;
				}
			}

			&.asteria--state-no-icon {
				@apply px-11;

				&.asteria--state-active {
					--border-width: 5px;

					padding-left: calc(
						theme('spacing.11') - var(--border-width)
					);
					border-left: var(--border-width) solid #ee7023;
					background-color: rgba(212, 196, 188, 0.3);
				}
			}
		}

		.wings-sidebar__placeholder {
			@apply h-2 w-4 rounded-full;

			background-color: #ebe7e2;
		}

		.wings-sidebar__spacer {
			@apply my-4 w-full border-0 border-t border-solid border-border-normal;
		}
	}

	.wings-sidebar__collapse-button {
		@apply flex h-10 cursor-pointer flex-row items-center justify-center gap-3 bg-transparent px-4;

		.wings-sidebar__item__label {
			@apply flex-1;
			padding-left: 2px;
		}

		.wings-sidebar__item__postfix {
			@apply w-3;

			.asteria-component__icon {
				transition: all 0.2s;

				&,
				.asteria-component__icon-content {
					height: 9px;
					width: 9px;
				}

				.asteria-component__icon-content {
					background-color: rgb(128, 88, 79);
				}
			}
		}

		&.asteria--state-open {
			background-color: rgba(212, 196, 188, 0.2);

			.wings-sidebar__item__postfix {
				.asteria-component__icon {
					transform: rotate(180deg);
				}
			}
		}

		&.asteria--state-active {
			background-color: rgba(212, 196, 188, 0.2);

			.wings-sidebar__item__prefix {
				.asteria-component__icon {
					.asteria-component__icon-content {
						background-color: rgb(238, 112, 35);
					}
				}
			}
		}

		&:hover {
			background-color: rgba(212, 196, 188, 0.5);
		}
	}

	.wings-sidebar__collapse-content {
		background-color: rgba(212, 196, 188, 0.2);
	}
}
