@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__faq {
	@apply flex flex-col gap-8;
}

.asteria-component__faq-section {
	@apply grid gap-4;

	grid-template-columns: 1fr 1fr;

	@media (max-width: theme('screens.tablet')) {
		grid-template-columns: 1fr;
	}

	.asteria-component__faq-section__content {
		@apply flex flex-col gap-2;
	}

	.asteria-component__faq-section__questions {
		@apply overflow-hidden;

		.asteria-component__faq-question {
			&:not(:first-child) {
				@apply border-t-0;
			}
		}

		> .asteria-component__list__item {
			&:not(.asteria-component__faq-question) {
				> .asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply m-0 mt-4 h-auto p-0;
					}
				}
			}
		}
	}
}

.asteria-component__list {
	&.asteria--size-lg {
		.asteria-component__list__item {
			&.asteria-component__faq-question {
				@apply h-auto;
			}
		}
	}
}

.asteria-component__list__item {
	&.asteria-component__faq-question {
		@apply flex h-auto cursor-pointer items-baseline py-2;
		@apply border-0 border-y border-solid border-border-normal;

		min-height: theme('spacing.12');

		> .asteria-component__typography {
			@apply flex-1;
		}
	}
}

.asteria-component__faq-modal {
	> .asteria-component__wrapper {
		> .asteria-component__wrapper-header {
			> .asteria-component__wrapper-header__content {
				> .asteria-component__typography {
					@apply whitespace-nowrap;
				}
			}
		}
	}
}
