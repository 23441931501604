@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__introduction {
	@apply flex flex-col gap-8;

	.asteria-page__wrapper {
		@apply pb-10;

		&.asteria--type-guides {
			@apply py-0;

			.asteria-component__guides {
				@apply border-y-0;
			}
		}

		&.asteria--type-content {
			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}
		}
	}
}
