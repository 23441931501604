@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__button {
	/* Default */

	.asteria-component__spinner {
		--progress__width: theme('spacing.5');
		--progress__height: theme('spacing.5');

		@apply ml-4;
	}

	&:disabled,
	&.asteria--state-disabled {
		@apply cursor-default;

		&[type='submit'],
		&[type='reset'] {
			@apply pointer-events-none;
		}
	}

	&:not(:disabled):not(.asteria--state-disabled) {
		@apply cursor-pointer;
	}

	@apply relative
		inline-flex
		h-10
		items-center
		whitespace-nowrap
		rounded-lg
		border-0
		bg-transparent
		px-4
		py-2
		focus:outline-none;

	> .asteria-component__badge {
		@apply absolute -top-1 -right-1;
	}

	.asteria-utils-position-first {
		@apply mr-0;
	}

	.asteria-utils-position-last {
		@apply ml-auto;
	}

	.asteria-component__text {
		@apply m-0
		font-button
		text-base;
	}

	&:hover {
		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-icon-hover;
			}
		}
	}

	&.asteria--state-disabled {
		@apply opacity-50;

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-icon-disabled;
			}
		}

		&:hover {
			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-icon-disabled;
				}
			}
		}
	}

	/* Sizes */

	&.asteria--size-small,
	&.asteria--size-sm {
		@apply h-8
			px-2
			py-1;

		.asteria-component__spinner {
			--progress__width: theme('spacing.4');
			--progress__height: theme('spacing.4');
		}

		.asteria-component__text {
			@apply text-sm;
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		@apply h-12;

		.asteria-component__spinner {
			--progress__width: theme('spacing.6');
			--progress__height: theme('spacing.6');
		}

		.asteria-component__text {
			@apply text-lg;
		}
	}

	&.asteria--size-xs {
		@apply h-8
			px-3
			py-1;

		.asteria-component__spinner {
			--progress__width: theme('spacing.4');
			--progress__height: theme('spacing.4');
		}

		.asteria-component__text {
			@apply text-xs;
		}
	}

	/* Primary */

	&.asteria-component__button--variant-primary {
		@apply bg-button-primary-normal-background;

		.asteria-component__text {
			@apply text-button-primary-normal-text;
		}

		.asteria-component__progress {
			.asteria-component__progress__value {
				@apply bg-button-primary-normal-background;
			}
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-primary-normal-icon;
			}
		}

		&:hover {
			@apply bg-button-primary-hover-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-primary-hover-background;
				}
			}

			.asteria-component__text {
				@apply text-button-primary-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-primary-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply bg-button-primary-focus-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-primary-focus-background;
				}
			}

			.asteria-component__text {
				@apply text-button-primary-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-primary-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply bg-button-primary-disabled-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-primary-disabled-background;
				}
			}

			.asteria-component__text {
				@apply text-button-primary-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-primary-disabled-icon;
				}
			}
		}
	}

	/* Secondary */

	&.asteria-component__button--variant-secondary {
		@apply bg-button-secondary-normal-background;

		.asteria-component__progress {
			.asteria-component__progress__value {
				@apply bg-button-secondary-normal-background;
			}
		}

		.asteria-component__text {
			@apply text-button-secondary-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-secondary-normal-icon;
			}
		}

		&:hover {
			@apply bg-button-secondary-hover-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-secondary-hover-background;
				}
			}

			.asteria-component__text {
				@apply text-button-secondary-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-secondary-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply bg-button-secondary-focus-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-secondary-focus-background;
				}
			}

			.asteria-component__text {
				@apply text-button-secondary-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-secondary-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply bg-button-secondary-disabled-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-secondary-disabled-background;
				}
			}

			.asteria-component__text {
				@apply text-button-secondary-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-secondary-disabled-icon;
				}
			}
		}
	}

	/* Tertiary */

	&.asteria-component__button--variant-tertiary {
		@apply border
		border-solid
		border-button-tertiary-normal-border
		bg-button-tertiary-normal-background;

		.asteria-component__progress {
			.asteria-component__progress__value {
				@apply bg-button-tertiary-normal-background;
			}
		}

		.asteria-component__text {
			@apply text-button-tertiary-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-tertiary-normal-icon;
			}
		}

		&:hover {
			@apply border-button-tertiary-hover-border
				bg-button-tertiary-hover-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-tertiary-hover-background;
				}
			}

			.asteria-component__text {
				@apply text-button-tertiary-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-tertiary-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply border-button-tertiary-focus-border
			bg-button-tertiary-focus-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-tertiary-focus-background;
				}
			}

			.asteria-component__text {
				@apply text-button-tertiary-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-tertiary-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply border-button-tertiary-disabled-border
				bg-button-tertiary-disabled-background;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-tertiary-disabled-background;
				}
			}

			.asteria-component__text {
				@apply text-button-tertiary-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-tertiary-disabled-icon;
				}
			}
		}
	}

	/* Link */

	&.asteria-component__button--variant-link {
		@apply bg-button-link-normal-background
			no-underline;

		.asteria-component__text {
			// @apply underline;
			@apply text-button-link-normal-text;
		}

		&.asteria--state-has-href {
			.asteria-component__text {
				@apply underline;
			}
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-link-normal-icon;
			}
		}

		&:hover {
			@apply bg-button-link-hover-background;

			.asteria-component__text {
				@apply text-button-link-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-link-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply bg-button-link-focus-background;

			.asteria-component__text {
				@apply text-button-link-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-link-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply bg-button-link-disabled-background;

			.asteria-component__text {
				@apply text-button-link-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-link-disabled-icon;
				}
			}
		}
	}

	/* Href */

	&.asteria-component__button--variant-href {
		@apply bg-button-href-normal-background
			no-underline;

		.asteria-component__text {
			@apply underline;
			@apply text-button-href-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-href-normal-icon;
			}
		}

		&:hover {
			@apply bg-button-href-hover-background;

			.asteria-component__text {
				@apply text-button-href-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-href-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply bg-button-href-focus-background;

			.asteria-component__text {
				@apply text-button-href-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-href-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply bg-button-href-disabled-background;

			.asteria-component__text {
				@apply text-button-href-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-href-disabled-icon;
				}
			}
		}
	}

	/* Assist */

	&.asteria-component__button--variant-assist {
		@apply rounded-none
		border-b
		border-solid
		border-button-assist-normal-border
		bg-button-assist-normal-background
		px-0;

		&.asteria--size-small,
		&.asteria--size-sm {
			@apply px-0;
		}

		.asteria-component__text {
			@apply text-button-assist-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-assist-normal-icon;
			}
		}

		&:hover {
			@apply border-button-assist-hover-border
				bg-button-assist-hover-background;

			.asteria-component__text {
				@apply text-button-assist-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-assist-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply border-button-assist-focus-border
				bg-button-assist-focus-background;

			.asteria-component__text {
				@apply text-button-assist-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-assist-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply border-button-assist-disabled-border
				bg-button-assist-disabled-background;

			.asteria-component__text {
				@apply text-button-assist-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-assist-disabled-icon;
				}
			}
		}
	}

	/* Tab */

	&.asteria-component__button--variant-tab {
		@apply rounded-none border-b border-solid py-2 px-4;
		@apply border-button-tab-normal-border  bg-button-tab-normal-background text-button-tab-normal-text;

		.asteria-component__progress {
			.asteria-component__progress__value {
				@apply bg-button-tab-normal-background;
			}
		}

		.asteria-component__typography {
			@apply text-button-tab-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-button-tab-normal-icon;
			}
		}

		&:hover {
			@apply border-button-tab-hover-border  bg-button-tab-hover-background text-button-tab-hover-text;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-tab-hover-background;
				}
			}

			.asteria-component__typography {
				@apply text-button-tab-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-tab-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply border-button-tab-focus-border  bg-button-tab-focus-background text-button-tab-focus-text;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-tab-focus-background;
				}
			}

			.asteria-component__typography {
				@apply text-button-tab-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-tab-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply border-button-tab-disabled-border  bg-button-tab-disabled-background text-button-tab-disabled-text;

			.asteria-component__progress {
				.asteria-component__progress__value {
					@apply bg-button-tab-disabled-background;
				}
			}

			.asteria-component__typography {
				@apply text-button-tab-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-button-tab-disabled-icon;
				}
			}
		}
	}

	/* Select */

	&.asteria-component__button--variant-select {
		@apply w-full rounded-lg border border-solid p-2;
		@apply border-form-select-normal-border bg-form-select-normal-background text-form-select-normal-text;

		&.asteria--size-large,
		&.asteria--size-lg {
			@apply p-3;
		}

		.asteria-component__typography {
			@apply text-form-select-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content:not(.asteria-component__badge
					.asteria-component__icon-content) {
				@apply bg-form-select-normal-icon;
			}
		}

		&:hover {
			@apply border-form-select-hover-border bg-form-select-hover-background text-form-select-hover-text;

			.asteria-component__typography {
				@apply text-form-select-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-form-select-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			@apply border-form-select-focus-border bg-form-select-focus-background text-form-select-focus-text;

			.asteria-component__text {
				@apply text-form-select-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-form-select-focus-icon;
				}
			}
		}

		&.asteria--state-disabled {
			@apply border-form-select-disabled-border bg-form-select-disabled-background text-form-select-disabled-text;

			.asteria-component__text {
				@apply text-form-select-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-form-select-disabled-icon;
				}
			}
		}

		.asteria-component__forecaster-selector & {
			.asteria-component__icon {
				.asteria-component__icon-content:not(.asteria-component__badge
						.asteria-component__icon-content) {
					@apply bg-icon-normal;
				}
			}

			&:hover {
				.asteria-component__icon {
					.asteria-component__icon-content:not(.asteria-component__badge
							.asteria-component__icon-content) {
						@apply bg-icon-hover;
					}
				}
			}

			&.asteria--state-active {
				.asteria-component__icon {
					.asteria-component__icon-content:not(.asteria-component__badge
							.asteria-component__icon-content) {
						@apply bg-icon-focus;
					}
				}
			}

			&.asteria--state-disabled {
				.asteria-component__icon {
					.asteria-component__icon-content:not(.asteria-component__badge
							.asteria-component__icon-content) {
						@apply bg-icon-disabled;
					}
				}
			}
		}
	}

	/* Icons */

	&:not(.asteria-component__button--icon-only) {
		&.asteria-component__button--icon-position-first {
			@apply pl-0;
		}

		&.asteria-component__button--icon-position-last {
			@apply pr-0;
		}
	}

	&.asteria-component__button--icon-only {
		@apply h-10 w-10 justify-center p-0;

		&.asteria--size-xsmall,
		&.asteria--size-x-small,
		&.asteria--size-xs {
			@apply h-6 w-6;
		}

		&.asteria--size-small,
		&.asteria--size-sm {
			@apply h-8 w-8;
		}

		&.asteria--size-large,
		&.asteria--size-lg {
			@apply h-12 w-12;
		}
	}

	& > & {
		@apply rounded-none border-0 bg-transparent p-0 shadow-none;

		&:hover,
		&.asteria--state-disabled,
		&.asteria--state-active {
			@apply rounded-none border-0 bg-transparent p-0 shadow-none;
		}
	}
}
