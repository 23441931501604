@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__integration-actions {
	.asteria-component__integration-list {
		.asteria-component__list__item {
			--list__item__padding: #{spacing(2)} #{spacing(2)};
			display: flex;
			justify-content: space-between;
			.asteria-component__typography-group {
				margin-right: 40px;
				.asteria-component__text {
					text-align: left;
				}
			}
			.asteria-component__button {
				&:first-of-type {
					margin-right: #{spacing(1)};
				}
				&.asteria--state-loading {
					&.asteria-component__button--icon-check {
						.asteria-component__icon {
							display: none;
						}
					}
				}
			}
			.asteria-component__icon {
				&.asteria-component__button--icon-check {
					--list__item__icon__width: var(--icon__size-large__width);
					--list__item__icon__height: var(--icon__size-large__height);
				}
			}
			&.asteria-component__integration-action--status-processed {
				.asteria-component__icon {
					&.asteria-component__icon--check {
						width: var(--icon__size-large__width);
						height: var(--icon__size-large__height);
						padding: var(--icon__size-large__padding);
						margin: var(--icon__size-large__margin);
					}
				}
			}
		}
	}
}
