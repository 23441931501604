@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-page__support {
	@apply mb-8;

	> .asteria-component__actions {
		@apply mb-4;
	}

	.asteria-page__wrapper {
		&.asteria--variant-faq {
			@apply mb-8 pb-10;

			.asteria-component__accordion {
				@apply -mx-4;
			}

			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}
		}

		&.asteria--variant-support {
		}
	}

	.asteria-page__support-form {
		@apply -mx-8;

		@media (max-width: theme('screens.tablet')) {
			@apply -mx-4;
		}

		&.asteria--state-split {
			@apply grid;

			grid-template-columns: repeat(2, minmax(0, 1fr));

			@media (max-width: theme('screens.tablet')) {
				grid-template-columns: 1fr;

				.asteria-page__support-form__placeholder {
					@apply hidden;
				}
			}
		}

		.asteria-page__support-form__content {
			@apply box-border p-8;

			@media (max-width: theme('screens.tablet')) {
				@apply p-4;
			}

			.asteria-component__form {
				@apply gap-4;
			}
		}

		.asteria-page__support-form__placeholder {
			.asteria-component__placeholder-image {
				@apply bg-cover;
			}

			.asteria-component__contenter {
				@apply h-full;

				> .asteria-component__contenter__inner {
					@apply h-full;
				}
			}
		}
	}
}
