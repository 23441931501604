@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-search-part {
	&.asteria--type-filters {
		.asteria-component__badge {
			@apply -top-1 -right-1;
		}
	}

	&.asteria--type-switcher {
		.asteria-component__badge {
			@apply -top-1 -right-1;
		}

		.asteria-component__invoice-search-switch-handle {
			&.asteria--state-active {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-icon-focus;
					}
				}
			}
		}
	}
}
