@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__print-pages {
	> .asteria-component__print-pages-column {
		.asteria-component__print-pages-column-icon {
			&.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-icon-focus;
				}
			}
		}

		> .asteria-component__typography-group {
		}

		&.asteria--status-ERROR {
			> .asteria-component__typography-group {
				--color-icon-normal: theme('colors.alert.error.normal.border');
				--color-icon-focus: theme('colors.alert.error.normal.border');
				--color-icon-hover: theme('colors.alert.error.normal.border');

				.asteria-component__title {
					@apply text-alert-error-normal-border;
				}
			}
		}

		&.asteria--status-WARNING {
			> .asteria-component__typography-group {
				--color-icon-normal: theme(
					'colors.alert.warning.normal.border'
				);
				--color-icon-focus: theme('colors.alert.warning.normal.border');
				--color-icon-hover: theme('colors.alert.warning.normal.border');

				.asteria-component__title {
					@apply text-alert-warning-normal-border;
				}
			}
		}
	}
}
