@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-edit-tabs {
	@apply flex flex-col gap-2;

	.asteria-component__invoice-edit-tabs-navigation {
		@include scroll('x');
		@apply p-2 pt-8;

		> .asteria-component__badge__wrapper {
			> .asteria-component__badge {
				@apply -top-2 -right-2;
			}
		}
	}

	.asteria-component__invoice-edit-tab-content-wrapper {
		min-height: 300px;

		@apply flex flex-col gap-2;

		.asteria-component__select-error,
		.asteria-component__input-error {
			@apply col-span-full;

			&:empty {
				@apply hidden;
			}
		}

		&.asteria--type-details {
			.asteria-component__form-wrapper {
				> .asteria-component__wrapper-content {
					@apply grid;

					grid-template-columns: minmax(auto, 160px) minmax(0, 1fr);

					> .asteria-component__datepicker {
						@apply contents;
					}

					> .asteria-component__input,
					> .asteria-component__datepicker
						> .asteria-component__input,
					> .asteria-component__select {
						@apply contents;

						> .asteria-component__label__wrapper {
							@apply contents;
						}
					}

					> .asteria-component__form-v2__label {
						@apply contents;

						> .asteria-component__form-v-8__switch-wrapper {
							@apply contents;
						}
					}

					.asteria-component__form-v2__label {
						.asteria-component__form-v2__label-inner {
							grid-area: unset;
						}
					}

					.asteria-component__form-v2__input-wrapper {
						.asteria-component__form-v2__input-wrapper__inner {
							grid-area: unset;
						}

						.asteria-component__form-v2__error {
							@apply col-span-full;

							grid-area: unset;
						}
					}
				}
			}
		}

		&.asteria--type-contact,
		&.asteria--type-billing,
		&.asteria--type-delivery {
			.asteria-component__form-wrapper {
				.asteria-component__wrapper-content {
					@apply grid;
					grid-template-columns:
						minmax(auto, 160px) minmax(auto, 120px) minmax(
							auto,
							60px
						)
						minmax(0, 1fr);

					> .asteria-component__input,
					> .asteria-component__select {
						@apply contents;

						> .asteria-component__label__wrapper {
							@apply contents;

							.asteria-component__select__inner,
							.asteria-component__input__inner {
								grid-column: span 3;
							}
						}
					}
				}

				.asteria-component__input {
					&.asteria--type-city,
					&.asteria--type-zip {
						> .asteria-component__label__wrapper {
							.asteria-component__input__inner {
								grid-column: span 1;
							}
						}
					}
				}
			}
		}
	}

	.asteria-component__invoice-edit-tab-content {
		@apply px-2;
	}

	.asteria-component__input-reset {
		&.asteria--state-error {
			.asteria-component__typography {
				@apply text-alert-error-normal-text;
			}

			.asteria-component__input__inner {
				@apply border-alert-error-normal-border;

				input {
					@apply text-alert-error-normal-text;
				}

				.asteria-component__icon-content {
					@apply bg-alert-error-icon-normal-background;
				}

				&:hover {
					@apply border-alert-error-normal-border;

					.asteria-component__icon-content {
						@apply bg-alert-error-icon-normal-background;
					}
				}
			}

			&.asteria--state-active {
				.asteria-component__input__inner {
					@apply border-alert-error-normal-border;
				}
			}
		}
	}
}
