.asteria-component__main-form {
	width: 100%;
	--card-background: rgb(251, 242, 234);
	--title-color: #512b2b;
	.no-border {
		border-style: none;
	}
	.asteria-component__form_body {
		padding: 0px 16px 16px 16px;
		background-color: var(--card-background);
	}
	.asteria-component__form__section_title {
		color: var(--title-color);
		padding: 10px 0px;
	}
	.asteria-component__form__input_row {
		display: flex;
		justify-content: space-between;
		padding: 5px 0px;
	}
	.asteria-component__form__input_row_nested {
		display: flex;
		justify-content: space-between;
		width: 46%;
		align-items: flex-end;
	}
	.asteria-component__form__input_width {
		width: 46%;
	}
	.asteria-component__form__input_width-30 {
		width: 30%;
	}
	.asteria-component__form__input_width-65 {
		width: 65%;
	}
}

/*
.asteria-component__alert .asteria-component__alert__content .asteria-component__title {
    &.asteria-component__alert_title_left {
        text-align: left;
    }
}
 */
