@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-table-button {
	&.asteria--variant-remove {
		--spinner__width: theme('spacing.4');
		--spinner__height: theme('spacing.4');

		@apply flex cursor-pointer items-center justify-center rounded-full border border-solid border-border-normal bg-white py-2 px-6 shadow;
		@apply absolute bottom-4 left-1/2 z-50 -translate-x-1/2;

		&.asteria--state-loading {
			@apply pointer-events-none;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-link-normal-icon;
			}
		}

		.asteria-component__typography {
			@apply text-button-link-normal-text;
		}

		&:hover {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-button-link-hover-icon;
				}
			}

			.asteria-component__typography {
				@apply text-button-link-hover-text;
			}
		}
	}
}
