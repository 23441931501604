@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__settings {
	@apply pb-10;

	> .asteria-component__actions {
		@apply mb-4;
	}

	.asteria-component__settings {
		> .asteria-component__wrapper-content {
			@apply p-0;

			.asteria-component__tabs__content {
				@apply p-0;
			}
		}

		> .asteria-component__wrapper-footer {
			@apply px-0;
		}
	}

	> .asteria-page__wrapper {
		@apply pb-10;
	}
}
