@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-health {
			--color-graph-radial-background: #ebe7e2;

			.asteria-component__icon {
				&.asteria--variant-header {
					> .asteria-component__icon-content {
						background-color: var(--color-primary);
					}
				}
			}
		}
	}
}
