@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__support-box {
	@apply box-border flex flex-col gap-10;

	padding: 60px 0;

	> .asteria-component__typography {
		@apply text-center;
	}

	.asteria-component__button {
		@apply box-border h-auto w-auto gap-4 overflow-hidden px-10 py-8;

		.asteria-component__typography {
			@apply whitespace-normal text-left text-title-lg font-medium;
		}

		> .asteria-component__button {
			@apply p-0 pr-10;
		}

		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-10 w-10;
			}
		}
	}

	.asteria-component__group {
		@apply box-border grid-cols-auto-fit gap-4 px-8;

		@media (max-width: theme('screens.tablet')) {
			@apply p-0;

			grid-template-columns: 1fr;
			grid-auto-flow: unset;
		}
	}
}
