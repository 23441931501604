@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria__component-chart {
	@keyframes fade {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.6;
		}
		100% {
			opacity: 1;
		}
	}

	.asteria--animation-loading {
		@apply fill-skeleton-normal-background;

		animation: fade 1.5s infinite;
	}

	.asteria-component__graph-legend-prefix {
		> rect {
			rx: 9999;
			ry: 9999;
		}
	}

	.asteria-component__graph-tooltip-prefix {
		> rect {
			rx: 9999;
			ry: 9999;
		}
	}
}

.asteria-svg__forecast {
	mask: url(#mask-stripe);
}

.asteria__component-chart__pie {
}
