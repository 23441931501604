@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-page__reports {
	@apply pb-10;

	.asteria-page__wrapper {
		@apply h-full gap-4 pb-10;

		max-height: 700px;

		> .asteria-component__form {
			@apply gap-4 overflow-hidden;

			> .asteria-component__quick-filters {
				@apply grid gap-4;

				grid-template-columns: minmax(0, 1fr) auto;

				> .asteria-component__quick-filters-chips,
				> .asteria-component__button.asteria--variant-reset {
					@apply col-span-full;
				}

				> .asteria-component__quick-filters-chips {
					@apply flex gap-2;
				}

				> .asteria-component__button.asteria--variant-reset {
					@apply justify-self-center;
				}
			}

			> .asteria-component__table {
				@include scroll();

				@apply grid;

				grid-template-columns: minmax(0, 2fr) repeat(3, 1fr);

				> .asteria-component__table-row {
					@apply contents;

					> .asteria-component__table-cell {
						@apply border-0 border-b border-solid border-border-normal px-2;

						.asteria-component__typography {
							@apply truncate text-sm;
						}

						.asteria-component__button {
							@apply truncate;

							&.asteria-component__button--variant-link {
								@apply px-0;
							}
						}

						&.asteria--variant-loading {
							&::before {
								content: '';
								@apply h-2 w-full animate-pulse rounded-full bg-black/10;
							}
						}

						&.asteria--variant-currency,
						&.asteria--variant-total {
							@apply justify-end;
						}
					}

					&.asteria-component__table-header {
						.asteria-component__table-cell {
							@apply sticky top-0 z-10 bg-white;

							.asteria-component__typography {
								--color-text: rgba(110, 110, 115, 1);

								@apply text-sm font-bold;
							}

							.asteria-component__button {
								--color-icon: #565656;
								--color-icon-hover: #565656;
								--color-icon-focus: #565656;

								@apply p-0;

								.asteria-component__typography {
									@apply font-medium;
								}

								&.asteria--state-active {
									.asteria-component__typography {
										--color-text: rgba(0, 0, 0, 1);
									}

									.asteria-component__button {
										--color-icon: rgba(0, 0, 0, 1);
										--color-icon-hover: rgba(0, 0, 0, 1);
										--color-icon-focus: rgba(0, 0, 0, 1);
									}
								}

								&:not(.asteria--state-active):not(:hover) {
									> .asteria-component__button:not(.asteria--type-options) {
										@apply invisible;
									}
								}
							}
						}
					}
				}
			}

			> .asteria-component__placeholder {
				@apply flex flex-col gap-4 p-16;

				background-color: rgba(244, 244, 244, 1);

				.asteria-component__title {
					@apply text-primary-normal;
				}

				.asteria-component__icon {
					@apply box-content h-8 w-8 rounded-full;

					background-color: rgba(86, 167, 81, 1);

					.asteria-component__icon-content {
						@apply h-3 w-3 bg-white;
					}
				}

				> .asteria-component__group {
					@apply gap-4;
				}
			}
		}
	}
}
