@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--size-invoice-table-cell: minmax(min-content, max-content);
	--size-invoice-table-cell-number: minmax(0, max-content);
	--size-invoice-table-cell-client-number: minmax(0, max-content);
	--size-invoice-table-cell-client-name: minmax(0, max-content);
	--size-invoice-table-cell-message: minmax(theme('spacing.24'), 1fr);
	--size-invoice-table-cell-selector: minmax(max-content, 1fr);
	--size-invoice-table-cell-flexible: minmax(theme('spacing.20'), 1fr);

	--invoice-table-template: var(--size-invoice-table-cell-message)
		var(--size-invoice-table-cell-client-number)
		--size-invoice-table-cell-client-name
		var(--size-invoice-table-cell-selector) var(--size-invoice-table-cell)
		var(--size-invoice-table-cell) var(--size-invoice-table-cell) auto;

	@media (max-width: theme('screens.tablet')) {
		--size-invoice-table-cell: minmax(min-content, max-content);
		--size-invoice-table-cell-number: minmax(min-content, max-content);
		--size-invoice-table-cell-client-number: minmax(
			min-content,
			max-content
		);
		--size-invoice-table-cell-client-name: minmax(min-content, max-content);
		--size-invoice-table-cell-message: minmax(min-content, max-content);
		--size-invoice-table-cell-selector: minmax(min-content, max-content);
		--size-invoice-table-cell-flexible: minmax(min-content, max-content);
	}
}

.asteria-component__invoice-table__form {
}

.asteria-component__invoice-table {
	@apply grid overflow-hidden;

	grid-template-columns: var(--invoice-table-template);

	@include scroll('both');

	.asteria-component__typography {
		@apply truncate;
	}

	.asteria-component__button {
		@apply truncate;
	}

	&:not(.asteria--state-empty) {
		grid-auto-rows: min-content;
	}

	.asteria-component__invoice-table__spacer {
		grid-column: span var(--size, 1);
	}

	> .asteria-component__table-row {
		@apply contents;

		.asteria-component__label__wrapper {
			@apply flex;
		}

		&.asteria-component__table-header {
			.asteria-component__table-cell {
				@apply sticky top-0 z-10 bg-white;

				.asteria-component__button {
					@apply overflow-hidden p-0;

					> .asteria-component__typography {
						@apply overflow-hidden text-ellipsis whitespace-nowrap;
					}
				}

				&.asteria--state-active {
					.asteria-component__typography {
						@apply font-medium;
					}
				}

				&:not(.asteria--state-active):not(:hover) {
					.asteria-component__button {
						> .asteria-component__button:not(.asteria--type-options) {
							@apply invisible;
						}
					}
				}

				&.asteria--cell-tax,
				&.asteria--cell-total {
					> .asteria-component__button {
						@apply flex-row-reverse;
					}
				}
			}
		}

		&.asteria--state-error {
			> .asteria-component__table-cell {
				@apply bg-alert-error-normal-background;

				.asteria-component__text {
					@apply text-alert-error-normal-text;
				}

				> .asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-alert-error-icon-normal-background;
					}
				}

				.asteria-component__button {
					> .asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-error-icon-normal-background;
						}
					}

					&:hover {
						> .asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-alert-error-icon-normal-background;
							}
						}
					}
				}
			}
		}

		&.asteria--state-empty {
			> .asteria-component__table-cell {
				@apply flex h-full flex-col gap-4;

				grid-column: span var(--size, 1);
				padding: 60px;

				> .asteria-component__group {
					> .asteria-component__title {
						@apply text-content-page-title;
					}

					> .asteria-component__button {
						@apply pointer-events-none rounded-full;
					}
				}

				> .asteria-component__typography {
					@apply whitespace-normal;
				}

				> .asteria-component__button,
				> .asteria-component__invoice-search__cell
					.asteria-component__button {
					@apply m-0 h-auto w-auto p-0;
				}
			}
		}
	}

	.asteria-component__table-cell {
		@apply box-border overflow-hidden border-0 border-b border-solid border-border-normal p-2;

		> .asteria-component__typography {
			@apply overflow-hidden text-ellipsis whitespace-nowrap;
		}

		&.asteria--cell-selector {
			z-index: 1;

			@apply sticky left-0 bg-white;
		}

		&.asteria--cell-details,
		&.asteria--cell-remove-details,
		&.asteria--cell-custom-action {
			@apply gap-0 p-0;
		}

		&.asteria--cell-details {
			@apply items-center justify-end;
		}

		&.asteria--cell-client-delivery {
			.asteria-component__button.asteria-component__button--variant-link {
				@apply overflow-visible;
			}
		}

		&.asteria--cell-total,
		&.asteria--cell-tax {
			@apply justify-end;
		}

		.asteria-component__button.asteria-component__button--variant-link {
			@apply overflow-hidden px-0;

			.asteria-component__badge {
				@apply -right-1;
			}

			&.asteria--type-client-delivery {
				@apply pr-4;
			}
		}
	}
}
