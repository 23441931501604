@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__connecting__wrapper {
	@apply flex
		items-center
		justify-center
		p-4
		pb-0
		text-center;

	.asteria-component__connecting {
		@apply flex
				w-full
				flex-col
				gap-4;

		.asteria-component__text {
		}

		.asteria-component__progress {
		}
	}
}
