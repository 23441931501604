@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__guide-details {
	@apply pb-10;

	.asteria-page__wrapper {
		@apply pb-10;
	}

	> .asteria-component__actions {
		@apply mb-4;
	}

	.asteria-component__contenter {
		> .asteria-component__contenter__inner {
			@apply gap-8;

			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply m-0 h-auto w-auto p-0;

					> .asteria-component__button {
						@apply mr-1;
					}
				}
			}

			.asteria-component__typography-group {
				@apply flex flex-col gap-2;

				> .asteria-component__title {
					@apply mb-2;
				}
			}

			.asteria-component__group {
				&.asteria--type-split {
					@apply grid gap-8;

					grid-template-columns: 300px 1fr;

					.asteria-component__placeholder-image {
						@apply bg-cover bg-center;

						width: 300px;
						height: 182px;
					}
				}
			}
		}
	}
}
