@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-history {
	@apply box-border flex flex-col gap-4 p-6;
}

.asteria-component__invoice-history__list {
	@apply flex flex-col gap-2;
}

.asteria-component__invoice-history__list-row {
	@apply cursor-pointer;

	min-height: theme('spacing.12');

	.asteria-component__badge {
		@apply mr-2;
	}
}

.asteria-component__level {
	&.asteria--type-invoice-history {
		.asteria-component__dropdown__list {
			@apply border-0 shadow-md;
		}

		.asteria-component__dropdown__item {
			min-height: theme('spacing.16');
			min-width: theme('spacing.80');

			.asteria-component__invoice-history__list-row {
				@apply cursor-pointer p-2;

				.asteria-component__typography-group {
					@apply flex flex-col gap-1;
				}

				.asteria-component__badge {
					--color-dropdown-item-normal-icon: white;
				}
			}
		}
	}
}

.asteria-component__dropdown {
	&.asteria--type-invoice-history {
		> .asteria-component__dropdown__toggle {
			--color-button-assist-normal-text: #878787;
			--color-button-assist-hover-text: #878787;
			--color-button-assist-focus-text: #878787;
			--color-button-assist-normal-icon: #878787;
			--color-button-assist-hover-icon: #878787;
			--color-button-assist-focus-icon: #878787;

			.asteria-component__typography {
				@apply font-light;
			}

			.asteria-component__icon {
			}
		}
	}
}

.asteria-component__invoice-history-wrapper {
	@apply flex justify-end;
}
