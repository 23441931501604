@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-actionbar {
	@apply grid;

	grid-template-areas: 'actionbar-content';
	grid-template-columns: 1fr;

	&.asteria-state--actions {
		grid-template-areas: 'actionbar-content actionbar-actions';
		grid-template-columns: minmax(150px, 1fr) auto;

		@media (max-width: theme('screens.tablet')) {
			grid-template-areas:
				'actionbar-content'
				'actionbar-actions';
			grid-template-columns: 1fr;
		}

		&.asteria-state--currencies {
			grid-template-areas:
				'actionbar-content actionbar-actions'
				'actionbar-extra actionbar-extra';

			@media (max-width: theme('screens.tablet')) {
				grid-template-areas:
					'actionbar-content'
					'actionbar-extra'
					'actionbar-actions';
			}
		}
	}

	.asteria-component__actionbar__section--content {
		grid-area: actionbar-content;
	}

	.asteria-component__actionbar__section--actions {
		@apply flex-wrap;

		grid-area: actionbar-actions;

		@media (max-width: theme('screens.tablet')) {
			@apply flex-col;

			.asteria-component__button {
				@apply justify-center;
			}
		}
	}

	.asteria-component__actionbar__section--extra {
		grid-area: actionbar-extra;

		@include scroll('x');
	}
}
