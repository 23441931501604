@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

// * --- MODAL --- * //

.asteria-component__client {
	&.asteria-component__invoices__client {
		.asteria-component__wrapper-header {
			@apply pb-4;
		}

		.asteria-component__client-overview__details__info {
			@apply flex flex-col gap-4;

			.asteria-component__client-overview-details-navigation {
				> .asteria-component__badge__wrapper {
					> .asteria-component__badge {
						@apply -top-2 -right-2;
					}
				}
			}
		}

		.asteria-component__client-overview__details__info__item {
			&.asteria--state-error {
				--color-list-item-normal-icon: theme(
					'colors.alert.error.normal.icon'
				);
				--color-list-item-hover-icon: theme(
					'colors.alert.error.normal.icon'
				);
				--color-list-item-focus-icon: theme(
					'colors.alert.error.normal.icon'
				);

				@apply bg-alert-error-normal-background;

				.asteria-component__typography {
					@apply text-alert-error-normal-text;
				}
			}
		}

		.asteria-component__placeholder {
			@apply p-8;

			background-color: rgba(244, 244, 244, 1);

			> .asteria-component__group {
				@apply gap-4;

				grid-template-columns: auto 1fr;
			}

			.asteria-component__icon {
				@apply h-8 w-8 rounded-full bg-icon-normal;

				.asteria-component__icon-content {
					@apply h-3 w-3 bg-white;
				}
			}

			.asteria-component__title {
				@apply text-content-page-title;
			}

			.asteria-component__placeholder-section {
				@apply flex flex-col gap-4;
			}

			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply m-0 h-auto p-0;
				}
			}
		}

		.asteria-component__wrapper-content {
			.asteria-component__alert
				~ .asteria-component__client-overview__details__wrapper {
				@apply pt-4;
			}

			.asteria-component__client-overview {
				> .asteria-component__alert {
					@apply mx-6;
				}
			}
		}
	}
}
