@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-widget__layout-footer {
	@apply hidden;
}

.asteria-widget__layout-footer__content {
	@apply box-border flex items-center;

	margin-left: 70px;
	margin-right: 70px;

	@media (min-width: 2000px) {
		@apply mx-auto;

		max-width: 1600px;
	}

	@media (max-width: theme('screens.tablet')) {
		@apply mx-2;
	}

	> .asteria-component__group {
		@apply w-full;
	}

	.asteria-widget__layout-footer__section-right {
		@apply gap-8;

		> .asteria-component__typography {
			@apply h-8 px-2 py-1;
		}
	}

	.asteria-component__select--dropdown {
		@apply w-60;
	}

	@media (max-width: theme('screens.tablet')) {
		> .asteria-component__group {
			@apply flex-col gap-8;
		}

		.asteria-widget__layout-footer__section-left {
			@apply flex flex-col items-start;
		}

		.asteria-widget__layout-footer__section-right {
			@apply flex flex-col items-start gap-6;
		}
	}
}

.asteria-widget__layout-footer__navigation-item {
}
