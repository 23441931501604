@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__modal {
	&.asteria-component__onboarding-modal {
		&.asteria--variant-guided {
			@media not (max-width: theme('screens.tablet')) {
				> .asteria-component__wrapper,
				> .asteria-component__form > .asteria-component__wrapper {
					> .asteria-component__onboarding-wrapper {
						min-height: 60vh;
					}
				}
			}
		}
	}
}

.asteria-component__onboarding-step {
	@apply flex flex-col;

	&.asteria--variant-visma-administration {
		.asteria-component__onboarding-wrapper {
			> .asteria-component__wrapper-content {
				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply m-0 h-auto w-auto px-0;
					}
				}
			}
		}
	}

	&.asteria--variant-printer {
		.asteria-component__button {
			&.asteria-component__button--variant-link {
				@apply h-auto p-0;
			}
		}

		.asteria-component__unordered {
			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply h-auto;

					.asteria-component__typography {
						@apply text-left;

						word-wrap: break-word; /* Allows breaking long words to wrap properly */
						word-break: break-all; /* Ensures text can break at arbitrary points */
						overflow-wrap: anywhere; /* Breaks text at arbitrary points to prevent overflow */
						white-space: normal; /* Allows text to wrap onto the next line */
					}
				}
			}
		}
	}
}

.asteria-component__onboarding-wrapper {
	@apply flex flex-1 flex-col rounded-none;

	> .asteria-component__wrapper-content {
		@apply flex-1;
	}

	.asteria-component__badge__wrapper {
		.asteria-component__badge {
			@apply right-0 top-0;
		}
	}

	> .asteria-component__onboarding-placeholder-wrapper {
		@apply grid h-full;

		grid-area: onboarding-placeholder;
	}

	> .asteria-component__wrapper-content {
		grid-area: onboarding-content;
	}

	> .asteria-component__wrapper-footer {
		grid-area: onboarding-footer;
	}

	&.asteria--variant-placeholder {
		@apply grid grid-cols-2;

		grid-template-rows: minmax(0, 1fr) auto;

		grid-template-areas:
			'onboarding-content onboarding-placeholder'
			'onboarding-footer onboarding-placeholder';

		@media (max-width: theme('screens.tablet')) {
			@apply grid-cols-1;

			grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) auto;

			grid-template-areas:
				'onboarding-placeholder'
				'onboarding-content'
				'onboarding-footer';
		}
	}

	&.asteria--variant-placeholder-multi {
		.asteria-component__onboarding-placeholder {
			grid-column: 1;
			grid-row: 1;

			&.asteria--placement-last,
			&.asteria-component__linear-onboarding__placeholder--placement-last {
				@apply pb-10;
			}
		}
	}
}

.asteria-component__onboarding-placeholder {
	@apply box-border flex h-full flex-col justify-center p-4;

	background-color: #f2f2f2;

	&.asteria--type-background {
		@apply pointer-events-none bg-cover bg-center bg-no-repeat;
	}

	&.asteria--type-image {
		@apply pointer-events-none pr-0;
	}

	&.asteria-component__linear-onboarding__placeholder--placement-last,
	&.asteria--placement-last {
		@apply justify-end;
	}

	.asteria-component__onboarding-placeholder-image {
	}

	.asteria-component__onboarding-placeholder-contenter {
		z-index: 1;
	}

	&.asteria--status-info {
		@apply bg-alert-info-normal-background;

		.asteria-component__typography {
			@apply text-alert-info-normal-text;
		}

		.asteria-component__icon {
			@apply box-content rounded-full bg-alert-info-icon-normal-background p-4;

			.asteria-component__icon-content {
				@apply bg-alert-info-normal-icon;
			}
		}
	}

	&.asteria--status-success {
		@apply bg-alert-success-normal-background;

		.asteria-component__typography {
			@apply text-alert-success-normal-text;
		}

		.asteria-component__icon {
			@apply box-content rounded-full bg-alert-success-icon-normal-background p-4;

			.asteria-component__icon-content {
				@apply bg-alert-success-normal-icon;
			}
		}
	}

	&.asteria--status-warning {
		@apply bg-alert-warning-normal-background;

		.asteria-component__typography {
			@apply text-alert-warning-normal-text;
		}

		.asteria-component__icon {
			@apply box-content rounded-full bg-alert-warning-icon-normal-background p-4;

			.asteria-component__icon-content {
				@apply bg-alert-warning-normal-icon;
			}
		}
	}

	&.asteria--status-error {
		@apply bg-alert-error-normal-background;

		.asteria-component__typography {
			@apply text-alert-error-normal-text;
		}

		.asteria-component__icon {
			@apply box-content rounded-full bg-alert-error-icon-normal-background p-4;

			.asteria-component__icon-content {
				@apply bg-alert-error-normal-icon;
			}
		}
	}
}

.asteria-component__onboarding-configuration-wrapper {
	.asteria-component__wrapper-header {
		.asteria-component__wrapper-header__content {
			@apply overflow-visible;
		}
	}

	&.asteria--variant-guided {
		@apply fixed top-1/2 left-1/2;
		transform: translate(-50%, -50%);
		width: var(--asteria-modal-size-lg);

		.asteria-component__wrapper-content {
			@apply p-0;

			.asteria-component__onboarding-step__content__wrapper {
				@apply py-4 pl-6;
			}

			.asteria-component__onboarding-step__content__guide {
				@apply px-0 py-10;
			}
		}

		@media (max-width: 640px) {
			@apply h-full w-full;

			.asteria-component__onboarding-step__content {
				> div {
					@apply flex-col-reverse;
				}

				.asteria-component__onboarding-step__content__guide {
					@apply w-full flex-auto;
				}
			}
		}
	}
}
