@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__alert {
	@apply border-alert-default-normal-border bg-alert-default-normal-background;

	.asteria-component__alert__wrapper {
		.asteria-component__title {
			@apply text-alert-default-normal-title;
		}

		.asteria-component__text:not(.asteria-component__button
				.asteria-component__text) {
			@apply text-alert-default-normal-text;
		}

		.asteria-component__alert__wrapper-header,
		.asteria-component__alert__wrapper-content,
		.asteria-component__alert__wrapper-footer {
			@apply bg-alert-default-normal-background;
		}

		.asteria-component__alert__wrapper-header {
			.asteria-component__wrapper-header__content {
				.asteria-component__icon {
					@apply bg-alert-default-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-default-normal-icon;
					}
				}
			}

			.asteria-component__button--variant-close {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-alert-default-icon-close-normal-icon;
					}
				}
			}
		}

		.asteria-component__alert__wrapper-content {
			> .asteria-component__icon {
				@apply bg-alert-default-icon-normal-background;

				.asteria-component__icon-content {
					@apply bg-alert-default-normal-icon;
				}
			}
		}

		.asteria-component__alert__wrapper-footer {
			.asteria-component__button {
				@apply text-alert-default-link-normal-text;

				.asteria-component__typography {
					@apply text-alert-default-link-normal-text;
				}
			}
		}
	}

	&.asteria-component__alert--level-success {
		@apply border-alert-success-normal-border bg-alert-success-normal-background;

		.asteria-component__alert__wrapper {
			.asteria-component__title {
				@apply text-alert-success-normal-title;
			}

			.asteria-component__text:not(.asteria-component__button
					.asteria-component__text) {
				@apply text-alert-success-normal-text;
			}

			.asteria-component__alert__wrapper-header,
			.asteria-component__alert__wrapper-content,
			.asteria-component__alert__wrapper-footer {
				@apply bg-alert-success-normal-background;
			}

			.asteria-component__alert__wrapper-header {
				.asteria-component__wrapper-header__content {
					.asteria-component__icon {
						@apply bg-alert-success-icon-normal-background;

						.asteria-component__icon-content {
							@apply bg-alert-success-normal-icon;
						}
					}
				}

				.asteria-component__button--variant-close {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-success-icon-close-normal-icon;
						}
					}
				}
			}

			.asteria-component__alert__wrapper-content {
				> .asteria-component__icon {
					@apply bg-alert-success-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-success-normal-icon;
					}
				}
			}

			.asteria-component__alert__wrapper-footer {
				.asteria-component__button {
					@apply text-alert-success-link-normal-text;

					.asteria-component__typography {
						@apply text-alert-success-link-normal-text;
					}
				}
			}
		}
	}

	&.asteria-component__alert--level-warning {
		@apply border-alert-warning-normal-border bg-alert-warning-normal-background;

		.asteria-component__alert__wrapper {
			.asteria-component__title {
				@apply text-alert-warning-normal-title;
			}

			.asteria-component__text:not(.asteria-component__button
					.asteria-component__text) {
				@apply text-alert-warning-normal-text;
			}

			.asteria-component__alert__wrapper-header,
			.asteria-component__alert__wrapper-content,
			.asteria-component__alert__wrapper-footer {
				@apply bg-alert-warning-normal-background;
			}

			.asteria-component__alert__wrapper-header {
				.asteria-component__wrapper-header__content {
					.asteria-component__icon {
						@apply bg-alert-warning-icon-normal-background;

						.asteria-component__icon-content {
							@apply bg-alert-warning-normal-icon;
						}
					}
				}

				.asteria-component__button--variant-close {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-warning-icon-close-normal-icon;
						}
					}
				}
			}

			.asteria-component__alert__wrapper-content {
				> .asteria-component__icon {
					@apply bg-alert-warning-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-warning-normal-icon;
					}
				}
			}

			.asteria-component__alert__wrapper-footer {
				.asteria-component__button {
					@apply text-alert-warning-link-normal-text;

					.asteria-component__typography {
						@apply text-alert-warning-link-normal-text;
					}
				}
			}
		}
	}

	&.asteria-component__alert--level-info {
		@apply border-alert-info-normal-border bg-alert-info-normal-background;

		.asteria-component__alert__wrapper {
			.asteria-component__title {
				@apply text-alert-info-normal-title;
			}

			.asteria-component__text:not(.asteria-component__button
					.asteria-component__text) {
				@apply text-alert-info-normal-text;
			}

			.asteria-component__alert__wrapper-header,
			.asteria-component__alert__wrapper-content,
			.asteria-component__alert__wrapper-footer {
				@apply bg-alert-info-normal-background;
			}

			.asteria-component__alert__wrapper-header {
				.asteria-component__wrapper-header__content {
					.asteria-component__icon {
						@apply bg-alert-info-icon-normal-background;

						.asteria-component__icon-content {
							@apply bg-alert-info-normal-icon;
						}
					}
				}

				.asteria-component__button--variant-close {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-info-icon-close-normal-icon;
						}
					}
				}
			}

			.asteria-component__alert__wrapper-content {
				> .asteria-component__icon {
					@apply bg-alert-info-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-info-normal-icon;
					}
				}
			}

			.asteria-component__alert__wrapper-footer {
				.asteria-component__button {
					@apply text-alert-info-link-normal-text;

					.asteria-component__typography {
						@apply text-alert-info-link-normal-text;
					}
				}
			}
		}
	}

	&.asteria-component__alert--level-error,
	&.asteria-component__alert--level-validate,
	&.asteria-component__alert--level-pattern,
	&.asteria-component__alert--level-exists,
	&.asteria-component__alert--level-minLength,
	&.asteria-component__alert--level-maxLength,
	&.asteria-component__alert--level-required {
		@apply border-alert-error-normal-border bg-alert-error-normal-background;

		.asteria-component__alert__wrapper {
			.asteria-component__title {
				@apply text-alert-error-normal-title;
			}

			.asteria-component__text:not(.asteria-component__button
					.asteria-component__text) {
				@apply text-alert-error-normal-text;
			}

			.asteria-component__alert__wrapper-header,
			.asteria-component__alert__wrapper-content,
			.asteria-component__alert__wrapper-footer {
				@apply bg-alert-error-normal-background;
			}

			.asteria-component__alert__wrapper-header {
				.asteria-component__wrapper-header__content {
					.asteria-component__icon {
						@apply bg-alert-error-icon-normal-background;

						.asteria-component__icon-content {
							@apply bg-alert-error-normal-icon;
						}
					}
				}

				.asteria-component__button--variant-close {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-error-icon-close-normal-icon;
						}
					}
				}
			}

			.asteria-component__alert__wrapper-content {
				> .asteria-component__icon {
					@apply bg-alert-error-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-error-normal-icon;
					}
				}
			}

			.asteria-component__alert__wrapper-footer {
				.asteria-component__button {
					@apply text-alert-error-link-normal-text;

					.asteria-component__typography {
						@apply text-alert-error-link-normal-text;
					}
				}
			}
		}
	}
}
