@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__server-error {
	.asteria-component__button {
		&.asteria-component__button--variant-link {
			@apply px-0;

			.asteria-component__typography {
				@apply text-alert-error-link-normal-text;
			}
		}
	}
}
