@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__list {
	&.asteria-component__integration-list {
		@apply gap-8;

		.asteria-component__spinner {
			--progress__width: 22px;
			--progress__height: 22px;
			--spinner__border-width: 2px;
		}

		.asteria-component__list__group {
			.asteria-component__list__header {
				@apply border-0;

				.asteria-component__list__item {
					@apply w-full;

					.asteria-component__typography {
						@apply text-content-title-normal;
					}
				}
			}

			.asteria-component__integration-list__integration-description {
				@apply flex-1;
			}

			.asteria-component__list__item--create-integration {
				@apply flex justify-end border-0;
			}
		}
	}
}
