@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-page__invoices {
	@apply flex flex-col pb-10;

	> .asteria-component__introduction-actions {
		@apply mb-8;
	}

	> .asteria-component__stepper,
	> .asteria-component__form > .asteria-component__stepper {
		@apply mb-8;
	}

	> .asteria-component__actions,
	> .asteria-component__form > .asteria-component__actions,
	> .asteria-component__feedback,
	> .asteria-component__form > .asteria-component__feedback,
	> .asteria-component__alert,
	> .asteria-component__form > .asteria-component__alert {
		@apply mb-4;
	}

	.asteria-page__wrapper {
		@apply p-0;
	}

	&.asteria--variant-details {
		.asteria-page__wrapper {
			@apply pb-0;
		}
	}

	.asteria-page__invoices__content {
		@apply grid h-full overflow-hidden;

		grid-template-rows: auto auto minmax(0, 1fr);

		.asteria-component__invoice-table__form {
			max-height: 400px;
		}

		.asteria-component__loading {
			z-index: 11;
		}
	}

	.asteria-page__placeholder {
		> .asteria-component__text {
			@apply text-content-text-muted;
		}
	}
}

.asteria-page__invoices__action-loading {
	@apply flex flex-col items-center gap-2;

	> .asteria-component__group {
		@apply items-center justify-center;
	}

	.asteria-component__button {
		@apply rounded-full;
	}
}
