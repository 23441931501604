@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-account {
			.recharts-legend-wrapper {
				.asteria-component__legends {
					@apply flex-wrap items-center justify-center gap-x-4 gap-y-2;
				}
			}
		}
	}
}
