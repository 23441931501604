@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__print-page {
	&.asteria--status-ERROR {
		> .asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-error-normal-border;
			}
		}
	}

	&.asteria--status-WARNING {
		> .asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-warning-normal-border;
			}
		}
	}

	&.asteria--status-PROCESSED,
	&.asteria--status-IGNORED {
		> .asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-success-normal-border;
			}
		}
	}
}
