@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@keyframes bar_animation {
	0% {
		opacity: 0.1;
		height: 50%;
	}

	50% {
		opacity: 0.3;
		height: 25%;
	}

	100% {
		opacity: 0.1;
		height: 50%;
	}
}

@keyframes asteria-bar-dot-pulse {
	0% {
		box-shadow: 0 0 0 0 theme('colors.graph.dot.animation.pulse.0');
	}

	70% {
		box-shadow: 0 0 0 10px theme('colors.graph.dot.animation.pulse.70');
	}

	100% {
		box-shadow: 0 0 0 0 theme('colors.graph.dot.animation.pulse.100');
	}
}

.asteria-indicator__wrapper {
	@apply absolute left-1/2 flex h-full w-full items-end justify-center px-10 opacity-0;

	&.asteria--state-clickable {
		@apply cursor-pointer;
	}

	--height: calc(
		var(--graph-bar-indicator-size, 8px) +
			var(--graph-bar-indicator-offset, 8px) +
			var(--graph-bar-wrapper-height, 16px)
	);

	height: var(--height);
	top: calc(-1 * var(--height));

	.asteria-indicator {
		margin-bottom: var(--graph-bar-wrapper-height, 16px);
		border-left: var(--graph-bar-indicator-size, 8px) solid transparent;
		border-right: var(--graph-bar-indicator-size, 8px) solid transparent;
		border-top: var(--graph-bar-indicator-size, 8px) solid
			theme('colors.graph.xaxis.indicator.normal.background');
	}
}

.asteria-component__old__graph__bar {
	@apply relative;
	// @apply relative h-full;

	&.asteria-background {
	}

	.asteria-color__paid {
		@apply order-1;
	}

	.asteria-color__unpaid {
		@apply order-2;
	}

	.asteria-color__signed {
		@apply order-3;
	}

	.asteria-color__forecast {
		@apply order-4;
	}

	.asteria-color__factoring {
		@apply order-5;
	}

	.asteria-color__overdue {
		@apply order-6;
	}

	.asteria-graph-bar-main {
		@apply pointer-events-auto relative flex h-0 flex-col overflow-hidden rounded-b-lg bg-transparent;
		@include apply-colors('graph-bar-part');

		&.asteria-graph-bar-loading {
			@apply pointer-events-none bg-black;
			animation: bar_animation 1s infinite ease-in-out;
		}
	}

	.asteria-component__graph-part-bar {
		@apply cursor-pointer transition-opacity;

		background-color: var(--color);
		background-image: var(--image);

		&.asteria--state-hidden {
			@apply hidden;
		}

		&.asteria--state-invisible {
			@apply opacity-50;
		}
	}

	.asteria-indicator__wrapper {
		transform: rotate(180deg) translateX(50%);
	}

	.asteria-graph-bar-actions {
		@apply hidden justify-center;
	}

	&.asteria-state-active,
	&.asteria-state-hover,
	&:hover {
		.asteria-indicator__wrapper {
			@apply opacity-100;
		}
	}

	&:hover {
		.asteria-graph-bar-actions {
			@apply flex;
		}
	}

	.dot-wrapper {
		@apply flex justify-center;

		.asteria-component__graph__dot {
			@apply m-3 h-3 w-3;
			transform: none;

			&.asteria-component__graph__dot--animated {
				transform: none;
				animation: asteria-bar-dot-pulse 2s infinite;
			}
		}
	}

	.asteria-graph-bar-main {
		@apply overflow-visible;
	}
}

.asteria-graph {
	&.asteria-graph-grouped {
		.asteria-adjustable {
			transform: rotate(90deg) scale(1, -1);
		}
	}

	&.asteria-graph-stacked {
		.asteria-deposit {
			.asteria-adjustable {
				transform: rotate(90deg) scale(1, -1);
			}
		}
	}
}
