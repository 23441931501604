@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.wings.partner-payex {
	.asteria-widget__layout-header {
		z-index: 1;

		.asteria-widget__layout-header__logo {
			background-image: url('./images/logo.png');
		}
	}
}
