@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__layout__header {
	&.lf-header {
		@apply z-50 box-border bg-white;
		@apply fixed top-0 left-0 right-0;

		height: 64px;

		box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);

		> .asteria-component__group {
			@apply mx-auto box-border h-full pr-8 pl-2;

			max-width: var(--lf-width);

			.wings-fullscreen & {
				max-width: unset;
			}
		}

		.wings-header__logo {
			@apply cursor-pointer;

			background: url('./images/Logo.svg');

			height: 40px;
			width: 180px;

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		.wings-header__button {
			@apply box-border flex h-8 cursor-pointer items-center gap-2 py-1;

			border-radius: 8px;

			&.wings-header__button--variant-user {
				@apply px-3;

				background-color: rgba(243, 247, 251, 1);

				.asteria-component__typography {
					@apply font-button font-semibold;

					color: rgba(1, 91, 160, 1);
				}

				.asteria-component__icon {
					.asteria-component__icon-content {
						background-color: rgba(1, 91, 160, 1);
					}
				}
			}

			&.wings-header__button--variant-logout {
				@apply px-2;

				background-color: rgba(0, 125, 176, 1);

				.asteria-component__typography {
					@apply font-button font-semibold text-white;
				}
			}
		}

		.asteria--type-wrapper {
			@apply gap-6;
		}

		.wings-header__action {
			&.asteria--variant-icon {
				@apply flex h-10 w-10 items-center justify-center;

				.asteria-component__icon {
					.asteria-component__icon-content {
						background-color: rgba(1, 91, 160, 1);
					}
				}

				.asteria-component__badge {
					@apply -top-1 -right-1;
				}
			}
		}
	}
}

.asteria-component__layout__sidebar {
	&.lf-sidebar {
		background-color: rgba(0, 66, 122, 1);

		width: 261px;

		@include scroll();

		.asteria-component__layout__sidebar-sub {
			@apply flex flex-col;

			background-color: rgba(0, 32, 59, 1);

			gap: 1px;
		}

		> .wings-sidebar__item,
		> .asteria-component__layout__sidebar-sub > .wings-sidebar__item {
			@apply flex cursor-pointer items-center border-0 border-solid border-transparent;

			&:not(.asteria--state-collapsible) {
				@apply pr-3;
			}

			border-right-width: 3px;
			height: 54px;

			&.asteria--state-no-icon {
				@apply pl-3;
			}

			.wings-sidebar__placeholder {
				@apply rounded-full opacity-30;

				background-color: rgba(235, 231, 226, 1);
			}

			&.asteria--variant-sub {
				background-color: rgba(0, 46, 85, 1);

				.asteria-component__typography {
					color: #ffffffa6;
				}

				.asteria-component__icon {
					.asteria-component__icon-content {
						background-color: #ffffffa6;
					}
				}
			}

			&.asteria--state-active {
				border-color: rgba(227, 6, 19, 1);
			}

			&:hover,
			&.asteria--state-active {
				.asteria-component__typography {
					color: #ffffff;
				}

				.asteria-component__icon {
					.asteria-component__icon-content {
						background-color: #ffffff;
					}
				}
			}

			> .wings-sidebar__item__icon {
				@apply flex h-10 w-10 items-center justify-center;

				.wings-sidebar__placeholder {
					@apply h-4 w-4;
				}
			}

			> .wings-sidebar__item__label {
				@apply flex-1;

				.wings-sidebar__placeholder {
					@apply h-2 w-full;
				}
			}

			> .wings-sidebar__item__collapse {
				@apply flex aspect-square h-full items-center justify-center;

				&:hover {
					background-color: rgba(23, 83, 134, 1);
				}
			}

			.asteria-component__typography {
				@apply font-button text-white;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-white;
				}
			}
		}
	}
}

.asteria-component__layout__footer {
	&.lf-footer {
		@apply box-border bg-white;
		height: 64px;

		> .asteria-component__group {
			@apply mx-auto box-border h-full py-3 px-8;

			max-width: var(--lf-width);

			.wings-fullscreen & {
				max-width: unset;
			}
		}

		.wings-footer__logo {
			background: url('./images/image 4.png');

			height: 40px;
			width: 40px;

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		.asteria-component__button--icon-heart,
		.asteria-component__button--icon-heart:hover {
			.asteria-component__icon {
				.asteria-component__icon-content {
					background-color: rgba(173, 16, 21, 1);
				}
			}
		}

		.asteria--type-navigation {
			@apply gap-4;

			.wings-footer__link {
				@apply cursor-pointer hover:underline;
			}
		}
	}
}

.partner-lf {
	.asteria-component__auth-language {
		display: none;
	}
}
