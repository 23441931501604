@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-widget__layout-header {
	@apply hidden;
}

.asteria-widget__layout-header__content {
	@apply box-border flex items-center;

	margin-left: 70px;
	margin-right: 70px;

	@media (min-width: 2000px) {
		@apply mx-auto;

		max-width: 1600px;
	}

	@media (max-width: theme('screens.tablet')) {
		@apply mx-2;
	}
}

.asteria-widget__layout-header__navigation {
	@apply flex flex-1;
}

.asteria-component__button.asteria-widget__layout-header__navigation-item {
	@apply h-10 gap-2 border border-solid p-0 pr-4;

	@apply border-navigation-item-normal-border bg-navigation-item-normal-background;

	.asteria-component__typography {
		@apply border border-solid text-sm font-normal;

		@apply border-navigation-item-text-normal-border bg-navigation-item-text-normal-background text-navigation-item-text-normal-text;
	}

	> .asteria-component__button {
		@apply rounded;

		@apply border-navigation-item-icon-normal-border bg-navigation-item-icon-normal-background;

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-navigation-item-icon-normal-icon;
			}
		}
	}

	&:hover {
		@apply border-navigation-item-hover-border bg-navigation-item-hover-background;

		.asteria-component__typography {
			@apply border-navigation-item-text-hover-border bg-navigation-item-text-hover-background text-navigation-item-text-hover-text;
		}

		> .asteria-component__button {
			@apply border-navigation-item-icon-hover-border bg-navigation-item-icon-hover-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-navigation-item-icon-hover-icon;
				}
			}
		}
	}

	&.asteria--state-active {
		@apply border-navigation-item-focus-border bg-navigation-item-focus-background;

		.asteria-component__typography {
			@apply border-navigation-item-text-focus-border bg-navigation-item-text-focus-background text-navigation-item-text-focus-text;
		}

		> .asteria-component__button {
			@apply border-navigation-item-icon-focus-border bg-navigation-item-icon-focus-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-navigation-item-icon-focus-icon;
				}
			}
		}
	}
}

.asteria-component__button.asteria-widget__layout-header__navigation-item,
.asteria-component__dropdown.asteria-widget__layout-header__navigation-dropdown {
	&.asteria-navigation--type-home {
	}

	&.asteria-navigation--type-connections {
	}

	&.asteria-navigation--type-invoices {
	}

	&.asteria-navigation--type-settings {
		@apply ml-auto;

		&.asteria-component__dropdown {
			> .asteria-component__button {
				> .asteria-component__icon {
					> .asteria-component__icon-content {
						@apply bg-navigation-item-icon-normal-icon;
					}
				}
			}

			&.asteria--state-active {
				> .asteria-component__button {
					> .asteria-component__icon {
						@apply rotate-180;

						> .asteria-component__icon-content {
							@apply bg-black;
						}
					}
				}
			}
		}
	}

	&.asteria-navigation--type-help {
	}

	&.asteria-navigation--type-logout {
	}

	&.asteria-navigation--type-mobile-menu {
		@apply ml-auto hidden;
	}

	@media (max-width: theme('screens.tablet')) {
		&.asteria-navigation--type-home,
		&.asteria-navigation--type-connections,
		&.asteria-navigation--type-invoices,
		&.asteria-navigation--type-settings,
		&.asteria-navigation--type-help,
		&.asteria-navigation--type-logout {
			@apply hidden;
		}

		&.asteria-navigation--type-mobile-menu {
			@apply flex;
		}
	}
}

.asteria-widget__layout-header__menu {
	@apply fixed left-0 right-0 bottom-0 z-20 box-border hidden bg-white py-6 px-4;

	top: 100px;

	@include scroll();

	&.asteria--state-open {
		@apply block;
	}
}

.asteria-widget__layout-header__menu-content {
	@apply flex flex-col gap-8;
}

.asteria-widget__layout-header__menu-item {
	@apply py-4 pr-4;

	padding-left: calc(theme('spacing.4') * var(--level));

	&.asteria-navigation--type-settings {
		order: 996;
	}

	&.asteria-navigation--type-user-settings {
		order: 997;
	}

	&.asteria-navigation--type-user-profile {
		order: 998;
	}

	&.asteria-navigation--type-logout {
		order: 999;
	}
}
