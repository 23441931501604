@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-modal {
	&.asteria--type-review {
		@apply overflow-hidden;

		> .asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				@apply gap-8;
			}
		}

		.asteria-component__invoice-table {
			max-height: unset;
		}
	}

	&.asteria--type-batch-remove {
		.asteria-component__contenter {
			.asteria-component__button--variant-link {
				@apply h-auto p-0;
			}
		}
	}
}
