@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__modal {
	&.asteria-component__client-modal {
		&.asteria--type-list {
			.asteria-component__client-modal__filters {
				@apply mb-4;
			}

			.asteria-component__input {
				@apply mb-4;
			}

			.asteria-component__table {
				&.asteria--type-clients {
					@apply grid;

					grid-template-columns:
						minmax(0, 1fr)
						auto
						minmax(0, 1fr)
						auto;

					&.asteria--variant-service-invoice {
						grid-template-columns:
							minmax(0, 1fr)
							auto
							auto
							minmax(0, 1fr)
							auto;
					}

					&.asteria--state-delivery {
						// grid-template-columns: minmax(0, 1fr) auto auto;
					}

					--color-icon-normal: rgba(135, 135, 135, 1);
					--color-icon-hover: rgba(135, 135, 135, 1);
					--color-icon-focus: rgba(135, 135, 135, 1);

					.asteria-component__typography {
						@apply truncate;
					}

					.asteria-component__badge__wrapper {
						@apply flex flex-row-reverse items-center;

						.asteria-component__badge {
							@apply relative;
						}
					}

					> .asteria-component__table-row {
						@apply contents;

						&.asteria-component__table-header {
							.asteria-component__button {
								@apply px-0;

								&:not(.asteria--state-active) {
									.asteria-component__icon {
										@apply opacity-0;
									}
								}

								&:hover {
									.asteria-component__icon {
										@apply opacity-100;
									}
								}

								.asteria-component__icon {
									@apply transition-opacity;

									&,
									.asteria-component__icon-content {
										@apply h-3 w-3;
									}
								}
							}
						}

						&:not(.asteria-component__table-header) {
							.asteria-component__table-cell {
								@apply h-12;
							}
						}
					}

					.asteria-component__table-cell {
						@apply border-0 border-b border-solid border-border-normal px-2;

						&.asteria--type-empty {
							grid-column: 1/-1;
						}
					}

					&.asteria--state-errors {
						> .asteria-component__table-row {
							&.asteria--variant-error {
								.asteria-component__table-cell {
									&.asteria--type-error {
										.asteria-component__typography {
											@apply text-alert-error-normal-text;
										}
									}
								}
							}
						}
					}

					&:not(.asteria--state-errors) {
						> .asteria-component__table-row {
							&.asteria--variant-error {
								.asteria-component__table-cell {
									@apply bg-alert-error-normal-background;

									.asteria-component__typography {
										@apply text-alert-error-normal-text;
									}
								}
							}
						}
					}
				}
			}

			.asteria-component__list {
				.asteria-component__list__item {
					@apply cursor-pointer border-0 border-b border-solid border-border-normal;

					--color-icon-normal: rgba(135, 135, 135, 1);
					--color-icon-hover: rgba(135, 135, 135, 1);
					--color-icon-focus: rgba(135, 135, 135, 1);
				}

				.asteria-component__group {
					@apply flex-1;
				}

				.asteria-component__badge__wrapper {
					.asteria-component__badge {
						@apply absolute -top-1 -right-5;
					}
				}
			}
		}
	}
}
