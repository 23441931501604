@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__action-dialog {
	width: 400px;
	bottom: 16px !important;
	.asteria-component__wrapper {
		.asteria-component__wrapper-header {
			--dialog__header__color: #4c2d2c;
		}
		padding: #{spacing(1)} #{spacing(1, sm)};
		.asteria-component__wrapper-content {
			gap: 24px;
			display: grid;
		}
		.asteria-component__button {
			width: max-content;
		}
	}
}
.asteria-component__width_full {
	width: 100%;
}
.asteria-component__width_full-sidebar-open {
	margin-right: 300px;
}
