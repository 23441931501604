@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__integration-step__content {
	@apply flex flex-col gap-2;

	.asteria-component__integration-step__content__inner {
		@apply flex flex-col gap-4;
	}
}
