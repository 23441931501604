@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__integrations {
	@apply pb-10;

	> .asteria-page__wrapper {
		@apply pb-10;
	}

	> .asteria-component__stepper {
		@apply mb-8;
	}

	> .asteria-component__actions {
		@apply mb-4;
	}
}
