@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-page__layouts {
	> .asteria-component__stepper {
		@apply mb-8;
	}

	.asteria-page__wrapper {
		@apply pb-10;
	}
}
