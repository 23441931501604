@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-modal {
	&.asteria--type-details {
		> .asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				> .asteria-component__typography-group {
					&.asteria--state-error {
						@apply bg-alert-error-normal-background;

						.asteria-component__invoice-modal-placeholder {
							> .asteria-component__typography {
								@apply text-alert-error-normal-text;
							}

							> .asteria-component__icon {
								@apply h-4 w-4 rounded-full bg-alert-error-icon-normal-background p-1;

								.asteria-component__icon-content {
									@apply bg-alert-error-normal-icon;
								}
							}
						}

						> .asteria-component__typography {
							@apply text-alert-error-normal-text;
						}
					}
				}

				> .asteria-component__typography-group {
					@apply -mx-6 flex flex-col items-center justify-center bg-list-item-details-header-normal-background p-8;
				}

				> .asteria-component__invoice-modal__content {
					@apply -mx-6 flex flex-col gap-4 p-8;
				}

				.asteria-component__invoice-modal__section {
					@apply flex flex-col gap-1;

					> .asteria-component__list {
						@apply grid;

						grid-template-columns: minmax(min-content, max-content) 1fr;

						> .asteria-component__list__item {
							@apply contents;

							&.asteria--state-error {
								.asteria-component__list__cell {
									@apply bg-alert-error-normal-background;
									> .asteria-component__typography {
										@apply text-alert-error-normal-text;
									}
								}

								.asteria-component__button {
									> .asteria-component__typography {
										@apply text-alert-error-normal-text;
									}
								}
							}
						}

						.asteria-component__list__cell {
							@apply box-border flex flex-col justify-center border-0 border-b border-solid border-border-normal p-2;

							min-height: theme('spacing.12');
						}

						.asteria-component__button {
							&.asteria-component__button--variant-link {
								@apply h-auto p-0;
							}
						}
					}
				}

				.asteria-component__message-box__wrapper {
					> .asteria-component__button {
						@apply pl-0;
					}
				}
			}
		}

		.asteria-component__invoice-modal-error {
			@apply bg-alert-error-normal-background;

			.asteria-component__typography {
				@apply text-alert-error-normal-text;
			}

			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}

			.asteria-component__icon {
				@apply h-6 w-6 rounded-full bg-alert-error-icon-normal-background p-1;

				.asteria-component__icon-content {
					@apply bg-alert-error-normal-icon;
				}
			}
		}
	}
}
