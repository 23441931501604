@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-modal {
	&.asteria--type-review {
		@apply overflow-hidden;

		> .asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				@apply gap-8;
			}
		}

		.asteria-component__actions {
			@apply -mx-6 -mt-4 p-6;

			background-color: #fafafa;
		}

		.asteria-component__invoice-table {
			max-height: unset;
		}

		.asteria-page__invoices-review__batch-errors {
			@apply mb-4;

			.asteria-component__alert {
				.asteria-component__title {
					@apply p-0;
				}
			}
		}
	}
}
