.asteria-component__form__container {
	display: flex;
	width: 100%;
	.asteria-component__stepper-container {
		width: 40%;
		padding: 4% 0 0 4%;
	}
}
/*
.asteria-component__alert.asteria-component__alert--type-feedback .asteria-component__alert__content .asteria-component__title {
  &.asteria-component__head-alert {
    margin-left: 1%;
    margin-top: -5px;
    text-align: left;
    font-size: large;
  }
}
.asteria-component__alert .asteria-component__alert__content .asteria-component__text {
  &.asteria-component__main-text{
    margin-top: 6%;
    font-size: larger;
    text-align: center;
  }
}

.asteria-component__alert.asteria-component__alert--level-success .asteria-component__alert__content .asteria-component__text:first-of-type {
  &.asteria-component__sub-text{
    margin: 4% 0% 5% 0%;
    font-size: larger;
    text-align: center;
  }
}
 */
.asteria-component__text__divider:before {
	display: block;
	content: '';
	background: rgba(0, 0, 0, 0.1);
	height: 1px;
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 58px;
}
