@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-page-placeholder-normal-background: rgba(244, 244, 244, 1);
}

.asteria-widget__layout-main {
	@apply mx-auto mt-8 w-full max-w-6xl flex-1;

	@media (max-width: theme('screens.tablet')) {
		@apply mx-auto px-2;

		.asteria-component__typography {
			&.asteria--type-separator {
				@apply whitespace-normal text-center;
			}
		}
	}

	&.asteria--page-invoices,
	&.asteria--page-invoices-review {
		@apply mx-0;

		padding-left: 70px;
		padding-right: 70px;
		max-width: none;

		@media (min-width: 2000px) {
			@apply mx-auto px-0;

			max-width: 1600px;
		}

		@media (max-width: theme('screens.tablet')) {
			@apply mx-auto px-2;
		}
	}
}

.asteria-widget__layout {
	@apply flex w-full flex-col bg-background-normal;

	&:not(.asteria-menu--state-open) {
		@include scroll();
	}

	&.asteria-menu--state-open {
		@apply overflow-hidden;

		.asteria-component__stepper-step__icon {
			z-index: 0;
		}
	}
}

.asteria-page {
	&__wrapper {
		@apply relative box-border flex flex-col gap-8 px-8 pt-4 pb-0;

		.asteria-component__unordered {
			.asteria-component__unordered__item.asteria-component__unordered__item--copy {
				@apply mb-2;

				.asteria-component__typography {
					@apply text-left;
					line-break: anywhere;
					white-space: break-spaces;
				}
			}
		}

		@media (max-width: theme('screens.tablet')) {
			@apply px-4;
		}

		> .asteria-page__wrapper-content {
			@apply flex flex-col gap-8;
		}

		> .asteria-component__typography-group,
		> .asteria-component__group > .asteria-component__typography-group {
			@apply flex flex-col gap-4;

			.asteria-component__title {
				@apply overflow-hidden text-ellipsis whitespace-nowrap;
			}
		}

		> .asteria-component__guides {
			@apply -mx-8 border-0 border-y border-solid border-border-normal;

			@media (max-width: theme('screens.tablet')) {
				@apply -mx-4;
			}
		}
	}

	&__details {
		.asteria-page__navigation {
			@apply items-start;
			> .asteria-component__button {
				@apply mt-2;
			}
		}
	}
}

.asteria-page__placeholder {
	@apply flex flex-col items-center justify-center gap-4;

	padding: 60px;
	background-color: var(--color-page-placeholder-normal-background);

	> .asteria-component__group {
		@apply gap-4;

		.asteria-component__icon {
			@apply h-8 w-8 rounded-full bg-alert-success-icon-normal-background;

			.asteria-component__icon-content {
				@apply h-3 w-3 bg-alert-success-normal-icon;
			}
		}

		.asteria-component__typography {
			@apply text-content-page-title;
		}
	}
}

.asteria-component__placeholder-image {
	@apply h-full w-full bg-contain bg-right bg-no-repeat;
}

.asteria-component__snackbar {
	.asteria-component__wrapper-header__content {
		.asteria-component__icon {
			@apply box-content;
		}
	}
}
