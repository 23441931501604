@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	/* Text */
	// --color-stepper-normal-text: ;
	// --color-stepper-focus-text: ;
	// --color-stepper-disabled-text: ;

	/* Icon */
	// --color-stepper-icon-normal-background: ;
	// --color-stepper-icon-normal-border: ;
	// --color-stepper-icon-normal-icon: ;
	// --color-stepper-icon-normal-text: ;

	// --color-stepper-icon-disabled-background: ;
	// --color-stepper-icon-disabled-border: ;
	// --color-stepper-icon-disabled-icon: ;
	// --color-stepper-icon-disabled-text: ;
}

.asteria-component__stepper {
	@apply grid grid-flow-col;

	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

	@include scroll('x');

	> .asteria-component__stepper-step {
		&:first-child {
			@apply pl-0;

			&::before {
				@apply left-1/2;
			}
		}

		&:last-child {
			@apply pr-0;

			&::before {
				@apply right-1/2;
			}
		}
	}
}

.asteria-component__stepper-step {
	@apply relative flex cursor-pointer flex-col items-center justify-center gap-2 px-2;

	&::before {
		content: '';

		@apply absolute top-5 left-0 right-0 border-0 border-b-2 border-solid;
	}

	.asteria-component__stepper-step__icon {
		background-color: var(--color-stepper-icon-normal-background, white);
		border-color: var(--color-stepper-icon-normal-border, transparent);

		.asteria-component__icon {
			.asteria-component__icon-content {
				background-color: var(--color-stepper-icon-normal-icon);
			}
		}

		.asteria-component__typography {
			color: var(--color-stepper-icon-normal-text);
		}
	}

	.asteria-component__stepper-step__content {
		color: var(--color-stepper-normal-text);
	}

	&.asteria--state-active {
		.asteria-component__stepper-step__content {
			@apply font-medium;
		}
	}

	&.asteria--state-disabled {
		@apply pointer-events-none;

		.asteria-component__stepper-step__icon {
			background-color: var(
				--color-stepper-icon-disabled-background,
				white
			);
			border-color: var(
				--color-stepper-icon-disabled-border,
				transparent
			);

			.asteria-component__icon {
				.asteria-component__icon-content {
					background-color: var(--color-stepper-icon-disabled-icon);
				}
			}

			.asteria-component__typography {
				color: var(--color-stepper-icon-disabled-text);
			}
		}

		.asteria-component__stepper-step__content {
			color: var(--color-stepper-disabled-text);
		}
	}

	&.asteria--status-success {
		.asteria-component__stepper-step__icon {
			@apply border-alert-success-normal-border bg-alert-success-icon-normal-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-success-normal-icon;
				}
			}

			.asteria-component__typography {
				@apply text-alert-success-normal-text;
			}
		}

		.asteria-component__stepper-step__content {
			@apply text-alert-success-normal-text;
		}
	}
}

.asteria-component__stepper-step__icon {
	@apply rounded-full;

	@apply flex h-10 w-10 items-center justify-center;
	@apply border border-solid;

	z-index: 1;
}

.asteria-component__stepper-step__content {
	@apply whitespace-nowrap;
}
