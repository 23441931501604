@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__print-status {
	> .asteria-component__print-status-content {
		> .asteria-component__progress {
			--progress__circlethickness: theme('spacing.1');

			--progress__height: theme('spacing.6');
			--progress__width: theme('spacing.6');

			--progress__value__width: calc(
				var(--progress__width) - var(--progress__circlethickness) * 2
			);
			--progress__value__height: calc(
				var(--progress__height) - var(--progress__circlethickness) * 2
			);
		}
	}

	&.asteria--status-PENDING {
		> .asteria-component__print-status-content {
			> .asteria-component__typography {
			}

			> .asteria-component__icon {
				> .asteria-component__icon-content {
				}
			}
		}
	}

	&.asteria--status-ERROR {
		> .asteria-component__print-status-content {
			> .asteria-component__typography {
			}

			> .asteria-component__icon {
				> .asteria-component__icon-content {
					@apply bg-alert-error-normal-text;
				}
			}
		}
	}

	&.asteria--status-WARNING {
		> .asteria-component__print-status-content {
			> .asteria-component__typography {
			}

			> .asteria-component__icon {
				> .asteria-component__icon-content {
					@apply bg-alert-warning-icon-normal-background;
				}
			}
		}
	}

	&.asteria--status-SUCCESS {
		> .asteria-component__print-status-content {
			> .asteria-component__typography {
			}

			> .asteria-component__icon {
				> .asteria-component__icon-content {
					@apply bg-alert-success-normal-text;
				}
			}
		}
	}
}
