@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-page-invoices-batch-placeholder-background: rgba(244, 244, 244, 1);
}

.asteria-page__invoices-batch {
	@apply pb-10;

	> .asteria-component__stepper {
		@apply mb-8;
	}

	> .asteria-component__faq-section {
		@apply mt-20 mb-8;
	}

	> .asteria-page__wrapper {
		&.asteria--variant-split {
			@apply grid;

			grid-template-columns: repeat(2, 1fr);
		}
	}

	.asteria-page__wrapper-section {
		&.asteria--type-content {
			@apply px-8 py-4;

			> .asteria-component__wrapper {
				> .asteria-component__wrapper-header,
				> .asteria-component__wrapper-content,
				> .asteria-component__wrapper-footer {
					@apply p-6;
				}

				> .asteria-component__wrapper-header {
					.asteria-component__typography {
						@apply text-content-page-title;
					}
				}
			}
		}

		&.asteria--type-placeholder {
			background-color: var(
				--color-page-invoices-batch-placeholder-background
			);

			padding-top: 90px;
			padding-bottom: 90px;
			padding-left: 100px;
		}
	}

	.asteria-component__invoice-batch__help {
		@apply flex flex-col gap-4;

		> .asteria-component__group {
			@apply gap-4;

			.asteria-component__button {
				@apply m-0 h-auto justify-center p-0;
			}
		}
	}

	.asteria-component__invoice-batch__fields-sections {
		@apply flex flex-col gap-6;

		.asteria-component__invoice-batch__fields-section {
			> .asteria-component__typography {
				@apply mb-4;
			}

			> .asteria-component__invoice-batch__fields {
				&:not(:last-child) {
					@apply mb-1;
				}
			}
		}
	}

	.asteria-component__invoice-batch__fields {
		@apply w-full;

		.asteria-component__invoice-batch__fields-grid {
			@apply grid items-center gap-1;

			grid-template-columns: repeat(var(--size, 2), 1fr);
		}

		.asteria-component__invoice-batch__field {
			@apply grid items-center border-0 border-b-2 border-solid border-transparent bg-white;

			grid-template-columns: 1fr 1fr minmax(min-content, max-content);

			&.asteria--batch-field-from,
			&.asteria--batch-field-to {
				grid-template-columns: auto 1fr minmax(min-content, max-content);
			}

			&.asteria--status-success {
				@apply border-alert-success-normal-border bg-alert-success-normal-background;

				&:not(:hover) {
					.asteria-component__invoice-batch__field-section {
						&.asteria--type-action {
							@apply invisible;
						}
					}
				}
			}

			.asteria-component__invoice-batch__field-section {
				&:not(.asteria--type-action) {
					@apply p-2;
				}

				&.asteria--type-label {
					@apply text-right;
				}

				&.asteria--type-content {
					@apply overflow-hidden;

					.asteria-component__typography {
						@apply break-words;
					}
				}
			}
		}
	}

	&.asteria--variant-start,
	&.asteria--variant-settings {
		> .asteria-page__wrapper {
			@apply p-0;
		}
	}

	&.asteria--variant-confirmation,
	&.asteria--variant-success {
		> .asteria-page__wrapper {
			@apply mb-10 pb-10;
		}
	}

	&.asteria--variant-confirmation {
		.asteria-page__placeholder {
			@apply gap-6;

			> .asteria-component__typography {
				&.asteria--type-separator {
					@apply w-full;
				}
			}

			> .asteria-component__contenter
				> .asteria-component__contenter__inner
				> .asteria-component__group {
				@apply gap-4;

				.asteria-component__icon {
					@apply h-8 w-8 rounded-full bg-alert-success-icon-normal-background;

					.asteria-component__icon-content {
						@apply h-3 w-3 bg-alert-success-normal-icon;
					}
				}

				.asteria-component__typography {
					@apply text-content-page-title;
				}
			}
		}

		.asteria-component__actions {
			@apply w-full;
		}
	}

	&.asteria--variant-success {
		> .asteria-page__wrapper {
			> .asteria-component__wrapper {
				> .asteria-component__wrapper-content {
					@apply gap-8;
				}
			}
		}
	}
}

.asteria-component__invoice-batch__currencies {
	@apply flex flex-row items-center gap-2;

	.asteria-component__chip {
		--color-chip-focus-border: #6e9c6a;
	}
}
