@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__welcome {
	width: 100%;
	.asteria-component__welcome__card {
		display: flex;
		justify-content: center;
		align-items: center;
		--select__background-color: rgb(251, 242, 234);
		background-color: var(--select__background-color);
		margin: 16px 0px;
		@media screen and (max-width: 600px) {
			flex-direction: column;
		}
		.asteria-component__welcome__card__image__container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0px 40px;
			position: static;
			width: 492px;
			height: 441px;
			left: 0px;
			top: 0px;
			background: var(--select__background-color);
			flex: 0 0 auto;
			order: 0;
			align-self: stretch;
			margin: 0px 32px;
			.asteria-component__welcome__card__image__container_img {
				max-width: 100%;
			}
		}
		.asteria-component__welcome__card__text__container {
			padding: 10px;
			margin-right: 90px;
			@media screen and (max-width: 600px) {
				margin-right: 0px;
			}
			@media screen and (max-width: 900px) and (min-width: 600px) {
				margin-right: 0px;
			}
		}
		.asteria-component__welcome__card__text__container_button {
			margin-top: 8%;
		}
		.asteria-component__welcome__card__text__container_noBottom {
			margin-bottom: 0px;
		}
	}
}
