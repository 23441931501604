@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/SwedbankHeadline/font.css';
@import './fonts/Inter/font.css';
@import './fonts/LFRubrik/font.css';
@import './fonts/IntroCond/font.css';
@import './fonts/Futura/font.css';

@import './partners/payex/styles.scss';

html,
body,
#root {
	@apply h-full;
}

body {
	@apply m-0 font-sans;
	@include scroll();
}

.wings {
	@apply flex h-full flex-col bg-background-normal;

	@include scroll();

	&.widget-freja {
		&[class*='theme-swedbank'] {
			.wings__wrapper {
				.wings-sidebar {
					@apply hidden;
				}
			}

			.wings-header {
				@apply hidden;
			}
		}
	}

	&[class*='theme-swedbank'] {
		&[class*='partner-swedbank'] {
			.asteria-view__auth {
				background: url('./partners/swedbank/images/background.jpg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
		}
	}

	&[class*='theme-swedbank'] {
		.wings__wrapper {
			@apply grid flex-1;

			grid-template-areas: 'wings-content';
			grid-template-columns: 1fr;

			@media (min-width: 990px) {
				grid-template-areas: 'wings-sidebar wings-content';
				grid-template-columns: auto 1fr;

				.wings-sidebar {
					@apply block;
				}
			}
		}

		.wings-sidebar {
			width: 236px;
		}

		.wings-header {
			@apply block;
		}

		.wings__wrapper {
			@apply overflow-hidden;
		}
	}

	&[class*='theme-lf'] {
		.asteria-view__auth {
			&::before {
				@apply fixed top-6 left-6 h-10;

				content: '';
				background: url('./partners/lf/images/alt.svg');
				background-repeat: no-repeat;
				background-size: contain;
				width: 300px;
			}
		}

		.wings__wrapper {
			&:has(.asteria-component__financial-page.asteria--variant-loan) {
				@apply overflow-hidden;
			}
		}

		&:not(.wings-fullscreen) {
			.wings__wrapper {
				padding-left: calc((100vw - 261px - 965px) / 2);
				padding-right: calc((100vw - 261px - 965px) / 2);
			}
		}

		.asteria-view__auth {
			background: url('./partners/lf/images/background.jpg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		.site-id {
			z-index: 51;
		}

		.wings__wrapper {
			@apply grid h-auto flex-1;

			margin-top: 64px;

			grid-template-areas: 'wings-content';
			grid-template-columns: 1fr;

			@media (min-width: calc(261px + 965px + 1px)) {
				grid-template-areas: 'wings-sidebar wings-content';
				grid-template-columns: auto 1fr;

				.wings-sidebar {
					@apply block;
				}
			}
		}

		.wings-header,
		.wings-footer {
			@apply block;
		}
	}

	.wings__wrapper {
		@apply h-full;
	}

	.wings-header,
	.wings-sidebar,
	.wings-footer {
		@apply hidden;
	}

	.wings-header,
	.wings-footer {
		@apply shrink-0;
	}

	.wings-header {
		grid-area: wings-header;
	}

	.wings-sidebar {
		grid-area: wings-sidebar;
	}

	.wings-main {
		@apply relative flex h-full;

		grid-area: wings-content;
	}

	.wings-footer {
		grid-area: wings-footer;
	}
}

.site-id {
	@apply fixed bottom-1 left-1 opacity-20 hover:opacity-100;

	z-index: 1;
}
