@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__level-navigation {
	@apply h-12;

	.asteria-component__level-navigation-content {
		@apply flex h-10 items-center overflow-hidden;
	}
}

.asteria-component__level-navigation-selector-item {
	.asteria-component__chip {
		@apply pointer-events-none;
	}

	&.asteria--variant-create {
		.asteria-component__button {
			@apply w-full gap-2;
			> .asteria-component__button--icon-only {
				@apply h-auto w-auto;
			}
		}
	}

	.asteria-component__dropdown__item__postfix {
		.asteria-component__group {
			> .asteria-component__icon {
				@apply invisible;
			}
		}
	}

	&.asteria--state-visible {
		.asteria-component__dropdown__item__postfix {
			.asteria-component__group {
				> .asteria-component__icon {
					@apply visible;
				}
			}
		}
	}

	&.asteria--type-tag-adjusted {
		@apply hidden;

		&.asteria--state-visible {
			@apply flex;
		}
	}

	&.asteria--state-editable {
		&:not(:hover) {
			.asteria-component__dropdown__item__postfix {
				> .asteria-component__group {
					> .asteria-component__button {
						@apply hidden;
					}
				}
			}
		}

		&:hover {
			.asteria-component__dropdown__item__postfix {
				> .asteria-component__group {
					> .asteria-component__icon {
						@apply hidden;
					}
				}
			}
		}
	}
}
