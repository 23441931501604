@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-page__invoices {
	> .asteria-component__form {
		> .asteria-component__alert {
			@apply mb-4;
		}
	}

	&.asteria--variant-initial {
		.asteria-page__wrapper {
			@apply gap-0;
		}
	}
}

.asteria-component__invoice-table {
	&.asteria-component__invoice-review-table {
		@apply h-full min-h-0 w-full;

		max-height: 400px;

		.asteria-component__table-row {
			&.asteria--state-selected {
				.asteria-component__table-cell {
					background: transparent;
				}
			}

			.asteria-component__table-cell {
				height: 48px;

				> .asteria-component__select {
					@apply w-full;
				}
			}

			.asteria--cell-total {
			}
		}
	}
}

.asteria-page__invoices_review__actions {
	@apply items-center gap-4;

	&.asteria-component__wrapper-footer {
		@apply p-0 pt-8;
	}
}
