@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__prints-table {
	--width-column-base: auto;
	--width-column-flexible: minmax(0, 1fr);
	--width-column-id: unset;
	--width-column-createdAt: unset;
	--width-column-status: unset;
	--width-column-invoices: unset;
	--width-column-message: unset;
	--width-column-actions: unset;

	@media (max-width: theme('screens.tablet')) {
		--width-column-base: minmax(min-content, max-content);
		--width-column-flexible: minmax(min-content, max-content);
	}

	&.asteria-component__table {
		@apply grid h-full overflow-auto;

		.asteria-component__button {
			&,
			.asteria-component__typography {
				@apply truncate;
			}

			.asteria-component__button {
				@apply shrink-0;
			}
		}

		> .asteria-component__table-row {
			@apply contents;

			> .asteria-component__table-cell {
				@apply px-2;

				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply h-auto w-auto gap-2 p-0;
					}
				}
			}

			&:not(:last-child) {
				> .asteria-component__table-cell {
					@apply border-0 border-b border-solid border-border-normal;
				}
			}

			&.asteria--status-WARNING {
				> .asteria-component__table-cell {
					@apply bg-alert-warning-normal-background;

					> .asteria-component__typography {
						@apply text-alert-warning-normal-text;
					}

					> .asteria-component__button {
						> .asteria-component__icon {
							> .asteria-component__icon-content {
								@apply bg-alert-warning-normal-text;
							}
						}
					}
				}
			}

			&.asteria--status-ERROR {
				> .asteria-component__table-cell {
					@apply bg-alert-error-normal-background;

					> .asteria-component__typography {
						@apply text-alert-error-normal-text;
					}

					> .asteria-component__button {
						> .asteria-component__icon {
							> .asteria-component__icon-content {
								@apply bg-alert-error-normal-text;
							}
						}
					}
				}
			}
		}

		> .asteria-component__table-header {
			> .asteria-component__table-cell {
				@apply sticky top-0 z-10  bg-white;

				> .asteria-component__button {
					@apply px-0;

					.asteria-component__button {
						@apply opacity-0;
					}

					&:hover,
					&.asteria--state-active {
						.asteria-component__button {
							@apply opacity-100;
						}
					}
				}
			}
		}
	}
}
