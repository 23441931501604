@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__input-reset {
	.asteria-component__input__wrapper {
		@apply max-w-full;

		.asteria-component__input__wrapper__prefix--postfix {
			.asteria-component__icon {
				--color-icon-normal: var(--color-form-input-normal-icon);
				--color-icon-hover: black;
			}
		}

		> input {
			@apply min-w-0 bg-red-400;
		}
	}
}
