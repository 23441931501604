@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__invoice-modal {
	&.asteria--type-edit {
		.asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				> .asteria-component__typography-group {
					@apply -mx-6 flex flex-col items-center justify-center bg-list-item-details-header-normal-background p-8;

					&.asteria--state-error {
						@apply bg-alert-error-normal-background;

						.asteria-component__invoice-modal-placeholder {
							> .asteria-component__typography {
								@apply text-alert-error-normal-text;
							}

							> .asteria-component__icon {
								@apply h-4 w-4 rounded-full bg-alert-error-icon-normal-background p-1;

								.asteria-component__icon-content {
									@apply bg-alert-error-normal-icon;
								}
							}
						}

						> .asteria-component__typography {
							@apply text-alert-error-normal-text;
						}
					}
				}
			}
		}

		.asteria-component__invoice-modal-error {
			@apply bg-alert-error-normal-background;

			.asteria-component__typography {
				@apply text-alert-error-normal-text;
			}

			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}

			.asteria-component__icon {
				@apply h-6 w-6 rounded-full bg-alert-error-icon-normal-background p-1;

				.asteria-component__icon-content {
					@apply bg-alert-error-normal-icon;
				}
			}
		}
	}
}
