@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

// * --- MODAL --- * //

.asteria-component__client {
	&.asteria-component__invoices__client {
		.asteria-component__wrapper-content {
			@apply p-0;

			.asteria-component__client-overview__list,
			.asteria-component__client-overview__details__wrapper {
				@apply p-6 pt-0;
			}

			.asteria-component__client-overview__details__overview {
				@apply mb-4;
			}
		}
	}
}

//* --- ClientEdit --- * //

.asteria-component__client-overview__edit__list {
	@apply flex flex-col gap-4;

	.asteria-component__client-edit-section {
		&.asteria--variant-plain {
			@apply grid items-center gap-4;

			grid-template-columns: auto 1fr auto;

			> .asteria-component__select,
			> .asteria-component__input {
				@apply contents;

				> .asteria-component__label__wrapper {
					@apply contents;
				}

				.asteria-component__select__inner,
				.asteria-component__input__inner {
					grid-column: 2/-1;
				}
			}

			> .asteria-component__form-v2__label {
				@apply contents;

				.asteria-component__form-v2__label-inner,
				.asteria-component__form-v2__input-wrapper__inner {
					grid-area: unset;
				}

				.asteria-component__form-v2__input-wrapper__inner {
					grid-column: 2/-1;
				}
			}
		}

		.asteria-component__client-reference {
			@apply contents;

			> .asteria-component__group {
				@apply contents;

				> .asteria-component__select,
				> .asteria-component__input {
					@apply contents;

					> .asteria-component__label__wrapper {
						@apply contents;
					}
				}
			}

			> .asteria-component__button {
				grid-column: 1/-1;

				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}
		}

		.asteria-component__select-error,
		.asteria-component__input-error {
			&:empty {
				@apply hidden;
			}

			grid-column: 1/-1;
		}

		&.asteria--variant-table {
			@apply grid gap-4;

			grid-template-columns:
				auto
				minmax(0, 1fr)
				minmax(0, 1fr)
				minmax(0, 1fr);

			> .asteria-component__table-row {
				@apply contents;
			}

			.asteria-component__table-header {
				.asteria-component__table-cell {
					@apply justify-self-center;
				}
			}

			.asteria-component__typography {
				&.asteria--variant-label {
					@apply text-form-label-normal-text;
				}
			}
		}
	}
}
